import {
  Alert,
  AlertTitle,
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  css,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import CustomTextField from "../../components/CustomTextField";
import styles from "./bookingForm.module.css";
import SearchIcon from "@mui/icons-material/Search";
import CustomLabel from "../../components/CustomLabel";
import CustomSelect from "../../components/CustomSelect";
import ImageDiologueBox from "../../components/ImageDiologueBox";
import ReactSignatureCanvas from "react-signature-canvas";
import CanvasDraw from "react-canvas-draw";

import Scanner from "react-webcam-qr-scanner";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Appbar from "../../components/AppBar/Appbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import CreatableSelect from "react-select/creatable";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import CustomSelectValue from "../../components/CustomSelectValue";
import imageCompression from "browser-image-compression";
import {
  CLR_DLG_WHITE,
  CLR_LIGHT_GREY,
  CLR_LIGHT_WHITE,
  CLR_ORANGE,
} from "../../utils/colors";
import ErrorField from "../../components/ErrorField";
import ResultDiologueBox from "../../components/ResultDiologueBox";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { addEditBooking } from "../../redux/action/booking.action";
import ClearRoomDiologueBox from "../../components/ClearRoomDiologueBox";
import SignatureDiologue from "../../components/Diologues/SignatureDiologue";
import { trackPromise } from "react-promise-tracker";
import ClearFormDiologueBox from "../../components/Diologues/ClearFormDiologueBox";
import { AirlineSeatIndividualSuiteTwoTone, SignalWifiStatusbarNull } from "@mui/icons-material";
import { getAvailableRoomStatics } from "../../services/roomSevices";
import {
  addEditBookingData,
  getBookingsDetailsData,
  getCustomerDetailsData,
} from "../../services/bookingServices";
import {
  getCityListData,
  getCountryListData,
  getStateListData,
} from "../../services/utilsServices";
import CustomSnackBar from "../../components/CustomSnackBar";
import Compressor from "compressorjs";
import staticTexts from "../../utils/static-texts";

const MAX_FILE_SIZE = 1024;
function BookingForm() {
  const dispatch = useDispatch();
  let fileupload = useRef(null);
  const selectInputRef = useRef(null);

  const idProofTypes = [
    { id: "1", value: "Aadhar Card" },
    { id: "2", value: "Passport" },
    { id: "3", value: "Driving Licence" },
    { id: "4", value: "Voter ID" },
    { id: "5", value: "Others" },
  ];
  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [idImage, setIdImage] = useState(null);
  const [bookingId, setBookingId] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [roomCnt, setRoomCnt] = useState(1);
  const [idCnt, setIdCnt] = useState(1);

  const [searchVal, setSearchVal] = useState(null);
  const [searchError, setSearchError] = useState(false);

  const [resDlgOpn, setResDlgOpn] = useState(false);
  const [resultLabel, setResultLabel] = useState(null);

  const [ocrScan, setOcrScan] = useState(false);
  const [ocrFile, setOcrFile] = useState(null);
  const [customerId, setCustomerId] = useState(0);

  const [bookingStatus, setBookingStatus] = useState(null);
  const [clrDlgOpn, setClrDlgOpn] = useState(false);
  const [searchBoxFlag, setSearchBoxFlag] = useState(true);

  const [roomMuiOpt, setRoomMuiOpt] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [removeIcnFlg, setRemoveIcnFlg] = useState(false);
  const [signDlgOpn, setSignDlgOpn] = useState(false);
  const [signImage, setSignImage] = useState(null);
  const [signFile, setSignFile] = useState(null);

  const [purposeCmntFlg, setPurposeCmntFlg] = useState(false);

  const [idproofFlg, setIdproofFlg] = useState(false);

  const [indianId, setIndianId] = useState(null);
  const [idPassportFlg, setIdPassportFlg] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  const [draftFlag, setDraftFlag] = useState(true);
  const [fillErr, setFillErr] = useState(false);

  const [errVal, setErrVal] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isCustomerBlocked, setIsCustomerBlocked] = useState(0);
  const [searchClicked, setSearchClicked] = useState(false);
  const navigate = useNavigate();

  const initialFvalues = {
    id_proof_type: "",
    name: "",
    mobile: "",
    altranate_mobile: "",
    address: "",
    city: "",
    state: "",
    country: "",
    occupation: "",
    booking_source: "",
    proof_no: null,
    no_of_rooms: 0,
    rooms_details: [
      {
        type: "",
        values: [],
        value: "",
        no_of_persons: 0,
        check_in_date_time: new Date(),
        check_out_date_time: null,
      },
    ],
    purpose_of_visit: "",
    signature: "",
    id_image: "",
    cust_image: "",
    id_image_url: "",
    cust_image_url: "",
    signature_image_url: "",
    nationality: "indian",
    purpose_of_visit_comment: "",
    id_type_comment: "",
    id_type_passport: "",
    data_upload: "",
    id_datas: [{ file: "", url: "" }],
  };

  const USER_DATA = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));

  let initialErrors = {
    id_proof_type: "",
    name: "",
    mobile: "",
    altranate_mobile: "",
    address: "",
    state: "",
    country: "",
    occupation: "",
    booking_source: "",

    no_of_rooms: "",
    id_datas: [{ file: "", url: "" }],
    rooms_details: [
      {
        type: "",
        values: [],
        value: "",
        no_of_persons: 0,
        check_in_date_time: new Date(),
        check_out_date_time: null,
      },
    ],
    purpose_of_visit: "",
    purpose_of_visit_comment: "",
    id_type_comment: "",
    signature: "",
    room_details_data: "",
    signature: "",
    id_image: "",
    cust_image: "",
    id_image_url: "",
    cust_image_url: "",
    signature_image_url: "",
    nationality: "",
    id_type_passport: "",
    data_upload: "",
  };

  const [errors, setErrors] = useState({ ...initialErrors });

  const [values, setValues] = useState(initialFvalues);

  const handleInputDataChange = (e) => {
    let { name, value } = e.target;

    if (name == "mobile" || name == "altranate_mobile") {
      value = value.replace(/[^\w ]/g, "");
    }
    if (
      (name == "mobile" || name == "altranate_mobile") &&
      value.length >= 10
    ) {
      if (values?.nationality == "indian") {
        value = value.substr(0, 10);
      } else {
        value = value.substr(0, 15);
      }
    }

    if (
      name == "altranate_mobile" &&
      value.length >= 10 &&
      values?.nationality == "indian"
    ) {
      value = value.substr(0, 10);
    }

    if (
      name == "altranate_mobile" &&
      value.length >= 10 &&
      values?.nationality == "indian"
    ) {
      value = value.substr(0, 10);
    }

    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (name == "mobile" || name == "altranate_mobile") {
      if (e.target.value === "" || re.test(e.target.value)) {
        setValues({ ...values, [name]: value });
      } else {
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleNationalityChange = (e) => {
    let { name, value } = e.target;
    setSearchVal(null);

    if (value == "indian") {
      setValues({
        ...values,
        [name]: value,
        country: { id: indianId, label: "India" },
        id_proof_type: "",
        state: "",
        name: "",
        id_type_passport: "",
        mobile: "",
        altranate_mobile: "",
        address: "",
        occupation: "",
        city: "",
        rooms_details: [
          {
            type: "",
            values: [],
            value: "",
            no_of_persons: 0,
            check_in_date_time: new Date(),
            check_out_date_time: null,
          },
        ],
        purpose_of_visit: "",
        booking_source: "",
        data_upload: "",
        id_datas: [{ file: "", url: "" }],
      });
      setRoomCnt(1);
      setSignFile(null);
      setOcrFile(null);
      setImageFile(null);
      setSearchVal(null);
      setIdImage(null);
      setImage(null);
      setSignImage(null);
      setIdPassportFlg(false);
      getStateList(indianId);
    } else {
      setValues({
        ...values,
        [name]: value,
        country: "",
        state: "",
        city: "",
        address: "",
        name: "",
        mobile: "",
        altranate_mobile: "",
        id_proof_type: "Passport",
        occupation: "",
        rooms_details: [
          {
            type: "",
            values: [],
            value: "",
            no_of_persons: 0,
            check_in_date_time: new Date(),
            check_out_date_time: null,
          },
        ],
        purpose_of_visit: "",
        booking_source: "",
        data_upload: "",
        id_datas: [{ file: "", url: "" }],
      });
      setRoomCnt(1);
      setSignFile(null);
      setOcrFile(null);
      setImageFile(null);
      setSearchVal(null);
      setIdImage(null);
      setImage(null);
      setSignImage(null);
      setStates([]);
      setCities([]);
      setIdPassportFlg(true);
      setIdproofFlg(false);
    }
  };

  useEffect(() => {
    let newerrors = { ...errors };
    Object.keys(values).map((key) => {
      if (values[key]) {
        newerrors[key] = "";
      }
    });

    setErrors(newerrors);
  }, [values]);

  const [asOcr, setAsOcr] = useState();

  console.log("values", values);

  const scanOcr = async (bool) => {
    ocrScan ? setIdImage(source) : setImage(source);
    if (ocrScan) {
      if (bool) {
        let FormData = require("form-data");
        let ocrData = new FormData();
        ocrData.append("proof_image", ocrFile);
        let address = values.address,
          state = values.state,
          city = values.city,
          name = values.name;
        let proof_no = values.proof_no;
        let isBlocked = false;
        await trackPromise(
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/bookings/scanOcrImage`,
              ocrData
            )
            .then(async (res) => {
              if (res.data?.is_blocked) {
                setSource(null)
                setOcrFile(null)
                setImage("");
                setTstContent(`${res?.data?.blocked_data?.name} is blocked ${!!res?.data?.blocked_data?.blocking_comment ? `due to ${res?.data?.blocked_data?.blocking_comment}` : ""}`)
                setTstOpn(true);
                isBlocked = true;
              } else {
                if (res?.data?.data?.raw_text) {
                  const aadhaarRegex = /^\d{4}\s?\d{4}\s?\d{4}$/;
                  proof_no = res?.data?.data?.raw_text?.find(line => aadhaarRegex.test(line)) || null;
                }
                if (res?.data?.data?.document_details?.name) {
                  name = res?.data?.data?.document_details.name;
                }
                if (res?.data?.data?.document_details?.address) {
                  address = res?.data?.data?.document_details.address;
                  let addressSplit = address.split(" ");
                  if (addressSplit[addressSplit.length - 1].includes("-")) {
                    //condition to split pincode and state if they both are present in one string
                    let pincodeSplit =
                      addressSplit[addressSplit.length - 1].split("-");
                    addressSplit.pop();
                    addressSplit = [...addressSplit, ...pincodeSplit];
                  }
                  let addressIndex = addressSplit.length - 2; //To get the state string which is present before pin code
                  while (addressIndex >= 0) {
                    let stateIspresentIndex = states.findIndex(
                      (state) =>
                        state.label.toLowerCase() ===
                        addressSplit[addressIndex]
                          .replace(/[^a-z]/gi, "")
                          .toLowerCase()
                    );
                    if (stateIspresentIndex === -1) {
                      stateIspresentIndex = states.findIndex(
                        (state) =>
                          state.label.toLowerCase() ===
                          `${addressSplit[addressIndex - 1]
                            .replace(/[^a-z]/gi, "")
                            .toLowerCase()} ${addressSplit[addressIndex]
                              .replace(/[^a-z]/gi, "")
                              .toLowerCase()}`
                      );
                      if (stateIspresentIndex > -1) {
                        state = states[stateIspresentIndex];
                        addressIndex -= 1;
                        break;
                      } else {
                        addressIndex -= 1;
                      }
                    } else {
                      state = states[stateIspresentIndex];
                      addressIndex -= 1;
                      break;
                    }
                  }
                  if (state !== values?.state) {
                    city = "";
                    let {
                      data: { data: returnedCities },
                    } = await getCityListData(state.id);
                    setCities(returnedCities);
                    while (addressIndex >= 0) {
                      let cityIspresentIndex = returnedCities.findIndex(
                        (city) =>
                          city.label.toLowerCase() ===
                          addressSplit[addressIndex]
                            .replace(/[^a-z]/gi, "")
                            .toLowerCase()
                      );
                      if (cityIspresentIndex === -1) {
                        cityIspresentIndex = returnedCities.findIndex(
                          (city) =>
                            city.label.toLowerCase() ===
                            `${addressSplit[addressIndex - 1]
                              .replace(/[^a-z]/gi, "")
                              .toLowerCase()} ${addressSplit[addressIndex]
                                .replace(/[^a-z]/gi, "")
                                .toLowerCase()}`
                        );
                        if (cityIspresentIndex > -1) {
                          city = returnedCities[cityIspresentIndex];
                          addressIndex -= 1;
                          break;
                        } else {
                          addressIndex -= 1;
                        }
                      } else {
                        city = returnedCities[cityIspresentIndex];
                        addressIndex -= 1;
                        break;
                      }
                    }
                  }
                }

              }

            })
            .catch((err) => { })
        );

        let data = [...values.id_datas];
        let len = values?.id_datas?.length;

        data[len - 2].file = ocrFile;
        data[len - 2].url = source;

        if (isBlocked) {
          setValues({
            ...values,
            id_datas: [{ file: "", url: "" }],
          });
        } else {
          setValues({
            ...values,
            id_datas: data,
            address: address,
            state: state,
            city: city,
            name: name,
            proof_no: proof_no
          });
        }

      } else {
        let data = [...values.id_datas];
        let len = values?.id_datas?.length;

        data[len - 2].file = ocrFile;
        data[len - 2].url = source;

        setValues({
          ...values,
          id_datas: data,
        });
      }
    }
    setOpen(false);
  };

  const [arr, setArr] = useState([]);

  const params = new URLSearchParams(document.location.search);
  const booking_id = new URLSearchParams(document.location.search).get(
    "booking_id"
  );
  const room_no = new URLSearchParams(document.location.search).get("room_no");
  const room_status = new URLSearchParams(document.location.search).get(
    "room_status"
  );

  useEffect(() => {
    getCountryList();
    getRoomDetailsStatics();
  }, []);
  const getRoomDetailsStatics = async (val) => {
    let id = null;
    if (!!room_no && room_no !== undefined) {
      id = room_no;
    } else {
      id = 0;
    }
    if (!!room_no) {
      setEditFlag(true);
    }
    if (!!booking_id) {
      setDraftFlag(false);
    }

    await trackPromise(
      getAvailableRoomStatics(id)
        .then((res) => {
          let { data, error } = res?.data;

          let dataArr = [];
          let muiArr = [];

          data.map((item) => {
            let obj = {};
            let muiObj = {};
            obj.type = item?.type;
            obj.values = JSON.parse(item?.values);
            muiObj.value = item?.type;
            dataArr.push(obj);
            muiArr.push(muiObj);
          });

          setArr(dataArr);
          setRoomMuiOpt(muiArr);

          if (booking_id > 0 && booking_id !== undefined) {
            getBookingDetails(booking_id, dataArr);
            setSearchBoxFlag(false);
          }
        })
        .catch((err) => { })
    );
  };

  const getBookingDetails = async (bookingId, arrayData) => {
    await trackPromise(
      getBookingsDetailsData(bookingId, room_no)
        .then((res) => {
          let { data, error } = res?.data;

          let room_stats = [];
          let new_rooms = [];
          let presentRoomsArr = [];

          if (data) {
            if (data?.room_lines && data?.room_lines?.length > 0) {
              data?.room_lines.map((dataItem) => {
                arrayData?.map((item) => {
                  if (item?.type == dataItem?.room_type_name) {
                    new_rooms = [];
                    let val = item?.values;
                    val.map((data) => {
                      new_rooms.push({
                        value: data?.room__number,
                        id: data?.room_type_id,
                      });
                    });

                    room_stats.push({
                      type: dataItem?.room_type_name,
                      values: new_rooms,
                      value: dataItem?.room_type_id,
                      no_of_persons: dataItem?.no_of_persons,
                      check_in_date_time: dataItem?.checkin_date,
                      check_out_date_time: dataItem?.checkout_date,
                    });
                  }
                });
                presentRoomsArr.push(dataItem?.room_no);
              });

              let newArrayData = [];
              let muiArr = [];
              newArrayData = arrayData;

              arrayData?.map((item, index) => {
                let val = item?.values;
                let tempval = [];
                val.map((data) => {
                  if (
                    data.room_availability != "Occupied" ||
                    presentRoomsArr.includes(data?.room__number)
                  ) {
                    tempval.push(data);
                  }
                });
                if (tempval.length > 0) {
                  newArrayData[index].values = tempval;
                  let muiObj = {};
                  muiObj.value = item.type;

                  muiArr.push(muiObj);
                }
                newArrayData[index].values = tempval;
              });
              setArr(newArrayData);
              setRoomMuiOpt(muiArr);
            }
            // =========================================================

            let id_data_array = [];
            if (data?.id_proof_image_url && data?.id_proof_image_url != "") {
              data?.id_proof_image_url.map((item) => {
                let obj = {};
                obj.file = item?.key;
                obj.url = item?.url;
                id_data_array.push(obj);
              });
            } else {
              id_data_array = [{ file: "", url: "" }];
            }
            if (
              id_data_array.length >= 1 &&
              data?.id_proof_image_url &&
              data?.id_proof_image_url != ""
            ) {
              id_data_array.push({ file: "", url: "" });
            }

            const new_values = {
              id_proof_type: data?.id_proof,
              id_datas: id_data_array,
              name: data?.name,
              mobile: data?.mobile,
              proof_no: data?.proof_no,
              altranate_mobile: data?.altranate_mobile,
              address: data?.address,
              city: { id: data?.city_id, label: data?.city_name },
              country: {
                id: Number(data?.country_id),
                label: data?.country_name,
              },

              state:
                data?.state_id != null
                  ? { label: data.state_name, id: data.state_id }
                  : null,
              booking_source: data?.booking_source,
              check_in_date_time: data?.checked_in,
              check_out_date_time: data?.checkout_in,
              no_of_persons: Number(data?.no_of_persons),
              no_of_rooms: data?.room_lines.length,
              rooms_details:
                room_stats.length > 0
                  ? room_stats
                  : [
                    {
                      type: "",
                      values: [],
                      value: "",
                      no_of_persons: 0,
                      check_in_date_time: new Date(),
                      check_out_date_time: null,
                    },
                  ],
              heading_to: data?.heading_to,
              purpose_of_visit: data?.purpose_of_visit,
              booking_id: data?.booking_id,
              occupation: data?.occupation,
              cust_image: data?.cust_image,
              id_image: data?.id_proof_image,
              signature: data?.arrival_signature_image,
              nationality: data?.nationality,

              purpose_of_visit_comment: data?.purpose_of_visit_comment,
              id_type_comment: data?.id_type_comment,
              id_type_passport: data?.passport_no,
            };

            if (booking_id > 0 && booking_id !== undefined) {
              if (data?.state_id || data?.state_id > 0) {
                getStateList(data?.country_id);
              } else {
                getStateList(deafultIndianId);
              }
            }
            if (data?.room_lines && data?.room_lines.length <= 0) {
              setRoomCnt(1);
            } else {
              setRoomCnt(data?.room_lines?.length);
            }
            setCustomerId(data?.customer_id);

            setImage(data?.cust_image_url);
            setSignImage(data?.arrival_signature_image_url);

            if (data?.room_lines?.length > 1) {
              setRemoveIcnFlg(true);
            } else {
              setRemoveIcnFlg(false);
            }

            if (new_values?.purpose_of_visit == "Others") {
              setPurposeCmntFlg(true);
            }
            if (new_values?.id_proof_type == "Others") {
              setIdproofFlg(true);
            }
            if (new_values?.id_proof_type == "Passport") {
              setIdPassportFlg(true);
            }

            setValues({ ...values, ...new_values });
            setBookingId(data.booking_id);
          }
        })
        .catch((err) => { })
    );
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  let deafultIndianId = null;

  const getCountryList = async () => {
    await trackPromise(
      getCountryListData()
        .then((res) => {
          let { data } = res?.data;

          let newCountries = data?.map((d) => {
            return {
              id: d.id,
              label: d.value,
            };
          });

          let countryId;
          data?.map((d) => {
            if (d.value == "India") countryId = d.id;
          });
          setIndianId(countryId);
          deafultIndianId = countryId;

          setCountries(newCountries);
          if (booking_id > 0 && booking_id !== undefined) {
          } else {
            setValues({
              ...values,
              country: { id: countryId, label: "India" },
            });

            getStateList(countryId);
          }
        })
        .catch((err) => { })
    );
  };

  const getStateList = async (country_id) => {
    await trackPromise(
      getStateListData(country_id)
        .then((res) => {
          let { data } = res?.data;
          let newStates = data?.map((d) => {
            return {
              id: d.id,
              label: d.value,
            };
          });

          setStates(newStates);
        })
        .catch((err) => { })
    );
  };

  const getCityList = async (state_id) => {
    await trackPromise(
      getCityListData(state_id)
        .then((res) => {
          let { data } = res?.data;

          setCities(data);
        })
        .catch((err) => { })
    );
  };

  const handleChangeRoomtypeValue = (e, index) => {
    if (e.target.value) {
      let data = { ...values };

      var roomTypeId = 0;

      data?.rooms_details[index]?.values.map((item) => {
        if (item.id == e.target.value) {
          roomTypeId = item.id;
        }
      });
      data.rooms_details[index].id = roomTypeId;
      data.rooms_details[index].value = e.target.value;
      setValues(data);
    }
  };
  const handleSearch = async () => {
    if (searchVal?.length >= 7) {
      setSearchError(false);
      await trackPromise(
        getCustomerDetailsData({
          mobile_no: Number(searchVal),
          nationality: values.nationality,
        })
          .then((res) => {
            let { data } = res?.data;
            if (data?.is_blocked) {
              setTstContent(`${data?.name} is blocked ${!!data?.blocking_comment ? `due to ${data?.blocking_comment}` : ""}`)
              setTstOpn(true);
              setIsCustomerBlocked(1);
              setFieldDisable(true);
              setValues({ ...values, mobile: "" })
            } else {
              let id_data_array = [];
              if (data?.id_proof_image_url && data?.id_proof_image_url != "") {
                data?.id_proof_image_url?.map((item) => {
                  let obj = {};
                  obj.file = item.key;
                  obj.url = item.url;
                  id_data_array.push(obj);
                });
              } else {
                id_data_array = [{ file: "", url: "" }];
              }
              if (
                id_data_array.length >= 1 &&
                data?.id_proof_image_url &&
                data?.id_proof_image_url != ""
              ) {
                id_data_array.push({ file: "", url: "" });
              }

              const new_values = {
                id_datas: id_data_array,
                id_proof_type: data?.id_proof,
                name: data?.name,
                mobile: data?.mobile,
                altranate_mobile: data?.altranate_mobile,
                proof_no: data?.proof_no,
                address: data?.address,
                city: { id: data?.city_id, label: data?.city_name },
                state: { id: data?.state_id, label: data?.state_name },
                country: { id: data?.country_id, label: data?.country_name },
                occupation: data?.occupation,
                nationality: data?.nationality,
                id_type_passport: data?.passport_no,
                cust_image: data?.cust_image,
                id_image: data?.id_proof_image,
              };
              if (data?.id_proof == "Passport") {
                setIdPassportFlg(true);
              } else {
                setIdPassportFlg(false);
              }

              if (data?.state_id || data?.state_id > 0) {
                getStateList(data?.country_id);
              }
              setCustomerId(data?.customer_id);

              setImage(data?.cust_image_url);

              setValues({ ...values, ...new_values });
            }
          })
          .catch((err) => {
            if (searchClicked) {
              setTstContent(err?.response?.data?.error);
              setTstOpn(true);
            }
            setValues({
              ...initialFvalues,
              nationality: values.nationality,
              mobile: values.mobile,
              id_proof_type: values.id_proof_type,
              country: values.country,
            });
            setImage("");
            setCustomerId("");
            setIsCustomerBlocked(0);
            setFieldDisable(false);
          })
      );
    } else {
      setSearchError(true);
      setTstOpn(true);
      setTstContent("Enter Valid Mobile No");
    }
  };

  const clearForm = () => {
    setValues({ ...initialFvalues, country: values.country });

    setSignFile(null);
    setOcrFile(null);
    setImageFile(null);
    setSearchVal(null);
    setIdImage(null);
    setImage(null);
    setSignImage(null);

    setErrors({ ...initialErrors });
    setRoomCnt(1);
  };

  const validateForm = async (status) => {
    let stateValue = { ...values };

    let newerrors = { ...initialErrors };

    if (status === "arrival") {
      if (bookingId > 0 && bookingId !== undefined) {
        setResultLabel("Updated Successfully !!!");
      } else {
        setResultLabel("Booking Successfull !!!");
      }
      var regex = /^[a-zA-Z. ]{2,30}$/;

      if (!stateValue.name) {
        newerrors.name = "Name is Required";
      }
      if (stateValue.name && !regex.test(stateValue.name)) {
        newerrors.name = "Name Should be alphabets with minimum 2 Chars";
      }
      if (
        (!stateValue.mobile || stateValue.mobile.length < 10) &&
        stateValue.nationality == "indian"
      ) {
        newerrors.mobile = "Mobile No - Minimum 10 Numbers Required";
      } else {
        newerrors.mobile = "";
      }
      if (!stateValue.state) {
        newerrors.state = "State is Required";
      }
      if (!stateValue.address) {
        newerrors.address = "Address is Required";
      }
      if (!stateValue.occupation) {
        newerrors.occupation = "Occupation is Required";
      }
      if (!stateValue.booking_source) {
        newerrors.booking_source = "Booking Source is Required";
      }

      if (!stateValue.purpose_of_visit) {
        newerrors.purpose_of_visit = "Purpose Of Visit is Required";
      }
      if (!stateValue.id_proof_type) {
        newerrors.id_proof_type = "ID Type is Required";
      }

      if (
        stateValue.purpose_of_visit == "Others" &&
        !stateValue.purpose_of_visit_comment
      ) {
        newerrors.purpose_of_visit_comment = "POV Comment is Required";
      }

      if (stateValue.id_proof_type == "Others" && !stateValue.id_type_comment) {
        newerrors.id_type_comment = "ID Type Comment is Required";
      }
      if (
        stateValue.id_proof_type == "Passport" &&
        !stateValue.id_type_passport
      ) {
        newerrors.id_type_passport = "Passport No is Required";
      }

      let room_error_flag = false;
      let no_of_person_flag = false;

      let availableRooms = [];

      for (var i = 0; i < stateValue.rooms_details.length; i++) {
        if (
          stateValue.rooms_details[i].type.length <= 0 ||
          !stateValue.rooms_details[i].hasOwnProperty("value") ||
          stateValue.rooms_details[i].value.length <= 0 ||
          !stateValue.rooms_details[i].check_in_date_time
        ) {
          room_error_flag = true;

          break;
        } else if (
          Number(stateValue.rooms_details[i].no_of_persons) > 10 ||
          Number(stateValue.rooms_details[i].no_of_persons) < 1
        ) {
          no_of_person_flag = true;
        } else {
          availableRooms.push(stateValue.rooms_details[i].value);
        }
      }

      if (
        room_error_flag &&
        new Set(availableRooms).size !== availableRooms.length
      ) {
        newerrors.room_details_data =
          "Fill The Details and Room Number Should be Unique";
        setTstContent(newerrors.room_details_data);
      } else if (no_of_person_flag) {
        newerrors.room_details_data =
          "No of Persons Should be in between 1-10 ";
        setTstContent(newerrors.room_details_data);
      } else if (room_error_flag) {
        newerrors.room_details_data = "Fill the Room Details";
        setTstContent(newerrors.room_details_data);
      } else if (new Set(availableRooms).size !== availableRooms.length) {
        newerrors.room_details_data = "Room Number should be unique";
        setTstContent(newerrors.room_details_data);
      } else {
        newerrors.room_details_data = "";
        newerrors.rooms_details = "";
      }
      let errorTxt = "";
      if (!image || !signImage) {
        if (!image) errorTxt += "Image ";

        if (!signImage) errorTxt += errorTxt != "" ? ",Signature" : "Signature";
      }
      if (stateValue.id_datas.length == 1 && stateValue.id_datas[0].url == "") {
        errorTxt += errorTxt != "" ? ",Id " : "Id";
      } else {
        if (
          stateValue.id_datas.some(
            (item, index) =>
              item.url == "" && stateValue.id_datas.length - 1 != index
          )
        ) {
          errorTxt += errorTxt != "" ? ",Id " : "Id";
        }
      }

      if (errorTxt != "") {
        errorTxt += " are Required";
      }

      newerrors.data_upload = errorTxt;

      newerrors.id_datas = "";
      setErrors({ ...newerrors });

      for (let key in newerrors) {
        if (newerrors[key] !== "") {
          if (typeof newerrors[key] === "object") {
            setTstOpn(true);
          } else {
            setTstOpn(true);
            setTstContent(newerrors[key]);
          }
          window.scrollTo(0, 0);
          return;
        }
      }
      setButtonDisabled(true);
    } else {
      if (
        (!stateValue.mobile || stateValue.mobile.length < 10) &&
        stateValue.nationality == "indian"
      ) {
        newerrors.mobile = "Mobile No - Minimum 10 Numbers Required";
        setTstContent(newerrors.mobile);
      } else {
        newerrors.mobile = "";
      }
      if (
        stateValue.id_proof_type == "Passport" &&
        !stateValue.id_type_passport
      ) {
        newerrors.id_type_passport = "This Field is Required";
      }

      let room_error_flag = false;
      let no_of_person_flag = false;

      let availableRooms = [];

      for (var i = 0; i < stateValue.rooms_details.length; i++) {
        if (!stateValue.rooms_details[i].hasOwnProperty("value")) {
          room_error_flag = true;

          break;
        } else if (Number(stateValue.rooms_details[i].no_of_persons) > 10) {
          no_of_person_flag = true;
        } else {
          availableRooms.push(stateValue.rooms_details[i].value);
        }
      }
      if (
        room_error_flag &&
        new Set(availableRooms).size !== availableRooms.length
      ) {
        newerrors.room_details_data =
          "Fill The Details and Room Number Should be Unique";
        setTstContent(newerrors.room_details_data);
      } else if (no_of_person_flag) {
        newerrors.room_details_data = "Maximum No of Person per Room is 10";
        setTstContent(newerrors.room_details_data);
      } else if (room_error_flag) {
        newerrors.room_details_data = "Fill The Room Details";
        setTstContent(newerrors.room_details_data);
      } else if (new Set(availableRooms).size !== availableRooms.length) {
        newerrors.room_details_data = "Room Number should be Unique";
        setTstContent(newerrors.room_details_data);
      } else {
        newerrors.room_details_data = "";
        newerrors.rooms_details = "";
      }

      newerrors.rooms_details = "";
      newerrors.id_datas = "";
      setErrors({ ...newerrors });

      for (let key in newerrors) {
        if (newerrors[key] != "") {
          window.scrollTo(0, 0);
          setTstOpn(true);
          return;
        }
      }
      setButtonDisabled(true);
    }
    let room_stats = [];

    stateValue.rooms_details?.map((data) => {
      room_stats.push({
        type: data?.type,
        room_type_id: data?.value,
        no_of_persons: data?.no_of_persons,
        check_in_date_time: data?.check_in_date_time,
      });
    });

    if (room_stats[0]?.type == "" || room_stats[0]?.type == null) {
      room_stats = "";
    }

    if (room_stats[0]?.room_type_id == "") {
      room_stats = "";
    }

    //pass customerId to check to edit the customer details in edit form otherwise put it as zero

    var FormData = require("form-data");
    var reqData = new FormData();

    let theOldIdData = [];
    for (let i = 0; i < stateValue.id_datas.length - 1; i++) {
      if (typeof stateValue.id_datas[i].file !== "string") {
        reqData.append(`id_image_${i}`, stateValue.id_datas[i].file);
      } else {
        theOldIdData.push(stateValue.id_datas[i].file);
      }
    }
    let fieldObject = {
      name: stateValue.name,
      mobile: stateValue.mobile,
      altranate_mobile: stateValue.altranate_mobile,
      address: stateValue.address,
      customer_id: customerId,
      booking_id: bookingId,
      id_images: stateValue.id_datas,
      country_id: stateValue.country?.id,
      city: stateValue.city,
      occupation: stateValue.occupation,
      booking_source: stateValue.booking_source,
      check_in_date_time: stateValue.check_in_date_time,
      check_out_date_time: stateValue.check_out_date_time,
      no_of_persons: stateValue.no_of_persons,
      purpose_of_visit: stateValue.purpose_of_visit,
      id_proof_type: stateValue.id_proof_type,
      state: stateValue.state,
      room_details: room_stats,
      booking_status: status,
      cust_image: stateValue.cust_image,
      id_images: theOldIdData,
      signature: stateValue.signature,
      nationality: stateValue.nationality,
      purpose_of_visit_comment: stateValue.purpose_of_visit_comment,
      id_type_comment: stateValue.id_type_comment,
      id_type_passport: stateValue.id_type_passport,
      room_no: room_no,
      room_status: room_status,
      proof_no: stateValue.proof_no,
    };
    reqData.append("field", JSON.stringify(fieldObject));
    reqData.append("cust_image", imageFile);
    reqData.append("signature_image", signFile);
    await trackPromise(
      fetch(`${process.env.REACT_APP_API_URL}/bookings/addEditBooking`, {
        method: "post",
        body: reqData,
        headers: {
          mimeType: "multipart/form-data; ",

          Authorization: localStorage.getItem(staticTexts.ACCESS_TOKEN_TEXT),
          hotel_id: USER_DATA.hotelId,
        },
      })
        .then((res) => {
          if (res.status == 409) {
            setTstContent(`The Room ${room_no} Already Occupied`);
            setTstOpn(true);
            setTimeout(() => {
              setTstOpn(false);
            }, 3000);
          } else {
            setResDlgOpn(true);
            setTimeout(() => {
              setResDlgOpn(false);
              navigate(-1);
            }, 3000);
          }
          setButtonDisabled(false);
        })
        .catch((err) => {
          setButtonDisabled(false);
          setTstContent(err?.response?.data?.message || "An error occurred");
          setTstOpn(true);
        })
    );
  };

  const citystyles = {
    menu: (base) => ({
      ...base,
      marginTop: 0,
    }),
  };

  const handleChangeRoomtype = (e, index) => {
    if (e.target.value) {
      let data = { ...values };
      data.rooms_details[index].type = e.target.value;

      setValues(data);

      let new_rooms = [];
      arr.map((obj) => {
        if (obj.type === e.target.value) {
          let val = obj.values;
          val.map((data) => {
            new_rooms.push({
              value: data?.room__number,
              id: data?.room_type_id,
            });
          });
        }
      });

      data.rooms_details[index].values = new_rooms;

      setValues({ ...data });
    }
  };

  const purpose_of_visit_values = [
    { value: "Personal" },
    { value: "Official" },
    { value: "Hospital" },
    { value: "Transit" },
    { value: "Others" },
  ];

  const booking_source_values = [
    { value: "Online" },
    { value: "Offline" },
    { value: "Walk-in" },
  ];
  const [loading, setLoading] = useState(false);
  const [viewFlag, setViewFlag] = useState(true);
  const [source, setSource] = useState(null);
  const fileUpload = useRef(null);
  const [actualOcrFile, setActualOcrFile] = useState(null);

  const [crntIdIndex, setCrntIdIndex] = useState(0);

  const handleCapture = async (target) => {
    if (ocrScan) {
      let newvalues = { ...values };
      let currentVal = newvalues?.id_datas;
      currentVal.push({
        file: "",
        url: "",
      });
      setValues({
        ...newvalues,
        id_datas: currentVal,
      });
    }
    if (target.files) {
      if (target.files?.length !== 0) {
        const file = target.files[0];

        const newUrl = URL.createObjectURL(file);

        const options = {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          setLoading(true);
          const compressedFile = await imageCompression(file, options);

          if (ocrScan) {
            setOcrFile(compressedFile);
          } else {
            setImageFile(compressedFile);
          }
          setSource(newUrl);
          setLoading(false);
        } catch (error) { }
      }
    }
  };

  const validateCurrentRoomStats = () => {
    let newvalues = { ...values };
    let currentRoomstats = newvalues?.rooms_details;
    let signFlag = true;
    currentRoomstats.map((item) => {
      if (
        item?.type == "" ||
        item?.values?.length < 1 ||
        item?.no_of_persons < 1
      ) {
        signFlag = false;
      }
    });

    return signFlag;
  };

  const [fieldDisable, setFieldDisable] = useState(true);

  useEffect(() => {
    if (values.nationality === "indian") {
      if (values.mobile?.length >= 10 && isCustomerBlocked == 0) {
        setFieldDisable(false);
      } else {
        setFieldDisable(true);
      }
    } else if (values.nationality != "indian") {
      if (values.mobile?.length >= 7) {
        setFieldDisable(false);
      } else {
        setFieldDisable(true);
      }
    }
  }, [values.nationality, values.mobile?.length]);

  return (
    <Box id={styles.mainbox}>
      <Appbar logo="true" />
      {/* <OcrImageRead /> */}

      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item lg={5} md={5} sm={5}>
          <Box
            id={styles.firstbox}
            onClick={(item) => {
              navigate(-1);
            }}
          >
            <img
              style={{ width: "14px", height: "24px", cursor: "pointer" }}
              src="/images/Icon_left_arrow.png"
              alt="left arrow"
            />

            <CustomLabel
              style={{
                color: "white",
                margin: "8px",
                fontweight: "700",
                fontSize: "18px",
              }}
              label="New Booking"
            />
          </Box>
        </Grid>
        <Grid item lg={5} md={5} sm={5}>
          <Box></Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          marginLeft: "50px",
          marginRight: "30px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CustomLabel label="Nationality" />
          <FormControl sx={{ color: "white" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="nationality"
              value={values?.nationality}
              defaultValue="indian"
              sx={{ color: "white", marginTop: "2px" }}
              onChange={(e) => {
                handleNationalityChange(e);
              }}
            >
              <FormControlLabel
                value="indian"
                control={
                  <Radio
                    sx={{
                      color: "white",
                    }}
                  />
                }
                label="Indian"
              />
              <FormControlLabel
                value="foriegner"
                control={<Radio sx={{ color: "white" }} />}
                label="Foreigner"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            justifyContent: "end",
          }}
          style={{ marinBottom: "12px", height: "50px" }}
        >
          {signImage ? (
            <Box sx={{ marginRight: "25px" }}>
              <Box>
                <Box
                  onClick={() => {
                    setSignDlgOpn(!signDlgOpn);
                  }}
                  id={styles.room_head}
                  sx={{
                    borderRadius: "100px",
                    cursor: "pointer",

                    maxHeight: "40px!important",
                  }}
                >
                  <img
                    id={styles.camera}
                    style={{ background: "white" }}
                    src={signImage}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Tooltip title="Signature">
              <img
                id={styles.camera}
                style={{
                  marginRight: "10px",
                  opacity: (values.mobile?.length >= (values.nationality === "foriegner" ? 7 : 10)) ? 1 : 0.4,
                }}
                onClick={() => {
                  if (values.mobile?.length >= (values.nationality === "foriegner" ? 7 : 10)) {
                    setSignDlgOpn(true);
                  }
                }}
                src="/images/icon_signature.png"
                alt="signature icon"
              />
            </Tooltip>
          )}
          {image ? (
            <Box
              sx={{
                marginRight: values?.id_datas.length > 1 ? "30px" : "20px",
              }}
            >
              <Box>
                <Box
                  onClick={() => {
                    setOpen(!open);
                    setViewFlag(false);
                    setSource(image);
                  }}
                  id={styles.room_head}
                  sx={{
                    borderRadius: "100px",
                    cursor: "pointer",
                    "&:hover": {
                      background: `${CLR_ORANGE}!important`,
                      padding: "4px",
                    },
                    maxHeight: "40px!important",
                  }}
                >
                  <Avatar alt="image" src={image} />
                  <Tooltip title="Delete Image ">
                    <img
                      id={styles.room_close_icon}
                      src="/images/image_delete_icon.png"
                      onClick={(e) => {
                        setImage(null);
                        e.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ) : (
            <Tooltip title="Camera">
              <label htmlFor="icon-button-file">
                <img
                  id={styles.camera}
                  style={{
                    marginRight: "20px",
                    opacity: (values.mobile?.length >= (values.nationality === "foriegner" ? 7 : 10)) ? 1 : 0.4,
                  }}
                  onClick={() => {
                    if (values.mobile?.length >= (values.nationality === "foriegner" ? 7 : 10)) {
                      setOcrScan(false);
                      setViewFlag(true);
                      setSource(null);
                      setOpen(true);
                    }
                  }}
                  src="/images/icon_camera.png"
                  alt="camera icon"
                />
              </label>
            </Tooltip>
          )}

          {values?.id_datas?.map((item, index) => (
            <Box key={index}>
              {item?.url != "" ||
                (item?.url == "" && values?.id_datas.length > 1) ? (
                <Box
                  sx={{
                    marginLeft: "-14px",
                    display:
                      values?.id_datas.length - 1 === index ? "none" : "block",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <Box
                      id={styles.room_head}
                      sx={{
                        borderRadius: "100px",
                        cursor: "pointer",
                        "&:hover": {
                          background: `${CLR_ORANGE}!important`,
                          padding: "4px",
                        },
                        maxHeight: "40px!important",
                      }}
                    >
                      <Avatar
                        sx={{ paddingRight: "-12px" }}
                        alt="image"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpen(!open);
                          setViewFlag(false);
                          setSource(item.url);
                        }}
                        src={item?.url}
                      />
                      <Tooltip title="Delete Id proof ">
                        <img
                          id={styles.room_close_icon}
                          src="/images/image_delete_icon.png"
                          onClick={(e) => {
                            let newvalues = [...values.id_datas];
                            let finalData = newvalues.splice(index, 1);
                            setValues({ ...values, id_datas: newvalues });
                            setIdCnt(idCnt - 1);
                            e.stopPropagation();
                          }}
                        />
                      </Tooltip>
                    </Box>
                    {index === values?.id_datas.length - 2 && index < 4 && (
                      <Tooltip title="Add ">
                        <label htmlFor="icon-button-file">
                          <AddCircleRoundedIcon
                            id={styles.id_plus_icon}
                            onClick={(e) => {
                              if (idCnt < 5) {
                                setIdCnt(idCnt + 1);
                                setCrntIdIndex(index);

                                setOcrScan(true);
                                setViewFlag(true);
                                setSource(null);
                                setOpen(true);

                                e.stopPropagation();
                              }
                            }}
                          />
                        </label>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              ) : (
                <Tooltip title="Id Proof">
                  <label htmlFor="icon-button-file">
                    <img
                      id={styles.camera}
                      style={{
                        opacity: (values.mobile?.length >= (values.nationality === "foriegner" ? 7 : 10)) ? 1 : 0.4,
                      }}
                      onClick={() => {
                        if (values.mobile?.length >= (values.nationality === "foriegner" ? 7 : 10)) {
                          setOcrScan(true);
                          setViewFlag(true);
                          setSource(null);
                          setOpen(true);
                          setCrntIdIndex(index);
                        }
                      }}
                      src="/images/icon_scan.png"
                      alt="scan icon"
                    />
                  </label>
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>
        <Box width={"100px"}></Box>
      </Box>

      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Grid item lg={5} md={5} sm={5}>
          <Box sx={{ paddingTop: "3px" }}>
            <CustomLabel
              label={values?.nationality != "indian" ? "Mobile" : "Mobile *"}
            />

            <Box id={styles.searchbox}>
              <TextField
                sx={{
                  marginTop: "-6px",
                  marginRight: "2px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    height: "38px",
                  },
                  background: "#FFFFFF",
                  borderRadius: "5px",
                }}
                type="text"
                fullWidth
                value={values?.mobile}
                name="mobile"
                onChange={(e) => {
                  handleInputDataChange(e);
                  setSearchClicked(false);
                  setSearchVal(e.target.value);
                }}
                placeholder="Mobile"
                variant="outlined"
                size="small"
                onKeyUp={() => {
                  const isForeigner = values.nationality === "foriegner";
                  if (searchVal?.length >= (isForeigner ? 7 : 10)) {
                    setSearchClicked(false);
                    handleSearch();
                  }
                }}
                InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Tooltip title="Search">
                          <SearchIcon
                            onClick={() => {
                              setSearchClicked(true);
                              handleSearch();
                            }}
                            sx={{ cursor: "pointer", color: CLR_ORANGE }}
                          />
                        </Tooltip>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box>
            <CustomLabel label="Alternate Mobile No " />
            <CustomTextField
              disabled={fieldDisable}
              opacity={fieldDisable ? 0.4 : 1}
              placeholder="name"
              name="altranate_mobile"
              type="text"
              value={values?.altranate_mobile}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              border="1px solid #707070"
              borderRadius="5px"
              height="5px"
              handleChange={(e) => {
                handleInputDataChange(e);
              }}
            />
          </Box>
          <Box>
            <CustomLabel label="Name *" />
            <CustomTextField
              disabled={fieldDisable}
              opacity={fieldDisable ? 0.4 : 1}
              placeholder="name"
              name="name"
              value={values?.name}
              border="1px solid #707070"
              borderRadius="5px"
              height="5px"
              handleChange={(e) => {
                handleInputDataChange(e);
              }}
            />
          </Box>

          <Box>
            <CustomLabel label="Country *" />

            <Autocomplete
              noOptionsText={"No Options"}
              disablePortal
              id="combo-box-demo"
              disableClearable
              disabled={fieldDisable}
              value={values?.country /*!== null ? values.country : ""*/}
              onChange={(e, newval) => {
                setValues({
                  ...values,
                  city: null,
                  state: null,
                  country: newval,
                });
                getStateList(newval.id);
              }}
              options={countries.filter((item) => {
                if (values?.nationality != "indian" && item.label == "India") {
                  return false;
                }
                return true;
              })}
              sx={{
                opacity: `${fieldDisable ? 0.4 : 1}`,
                background: "white!important",
                "& .MuiInputBase-input": {
                  borderRadius: "5px",
                  position: "relative",

                  background: "white!important",
                },
                "& .MuiSvgIcon-root": {
                  color: "#E98D12!important",
                },
                "& .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon": {
                  color: "#E98D12!important",
                },
                "&.css-11qa6m6-MuiAutocomplete-root .MuiInputBase-input": {
                  border: "none",
                },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  padding: "0px",
                },

                borderRadius: "5px",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined"></TextField>
              )}
            />
          </Box>

          <Box>
            <CustomLabel label="State *" />
            <CreatableSelect
              isDisabled={fieldDisable}
              options={states}
              name="state"
              value={values.state}
              getOptionValue={(option) => (option.id ? option.id : "")}
              isClearable
              onChange={(opt, meta, e) => {
                setValues({ ...values, city: null, state: opt });
                if (opt?.id != undefined || opt?.id != null) {
                  getCityList(opt?.id);
                } else {
                  setCities([]);
                }
              }}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: 0,
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "5px",
                  background: "white",
                  border: "none",
                  opacity: `${fieldDisable ? 0.4 : 1}`,
                }),
                input: (provided) => ({
                  ...provided,
                  padding: "2px",
                  borderRadius: "4px",
                  position: "relative",
                  color: "black",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: "#E98D12",
                }),
                clearIndicator: (provided) => ({
                  ...provided,
                  color: "#E98D12",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              formatCreateLabel={(inputValue) =>
                `Click here to add : "${inputValue}"`
              }
            />
          </Box>

          <Box>
            <CustomLabel label="City" />
            <CreatableSelect
              isDisabled={fieldDisable}
              opacity={values.mobile?.length >= 10 ? 1 : 0.4}
              options={cities}
              name="city"
              // ref={selectInputRef}
              value={values.city}
              getOptionValue={(option) => (option.id ? option.id : "")}
              isClearable
              onChange={(opt, meta, e) => {
                setValues({ ...values, city: opt });
              }}
              styles={{
                menu: (base) => ({
                  ...base,
                  marginTop: 0,
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "5px",
                  background: "white",
                  border: "none",
                  opacity: `${fieldDisable ? 0.4 : 1}`,
                }),
                input: (provided) => ({
                  ...provided,
                  padding: "5px",
                  borderRadius: "5px",
                  position: "relative",
                  color: "black",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: "#E98D12",
                }),
                clearIndicator: (provided) => ({
                  ...provided,
                  color: "#E98D12",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              formatCreateLabel={(inputValue) =>
                `Click here to add : "${inputValue}"`
              }
            />
          </Box>

          <Box>
            <CustomLabel label="Address *" />
            <TextareaAutosize
              disabled={fieldDisable}
              aria-label="empty textarea"
              name="address"
              value={values?.address}
              onChange={(e) => {
                handleInputDataChange(e);
              }}
              style={{
                width: "96%",
                border: "1px solid #707070",
                borderRadius: "5px",
                minHeight: "32px",
                paddingLeft: "10px",
                fontSize: "17px",
                fontFamily: "system-ui",
              }}
            />
          </Box>

          <Box>
            <CustomLabel label="Occupation *" />
            <CustomTextField
              disabled={fieldDisable}
              opacity={fieldDisable ? 0.4 : 1}
              placeholder="name"
              type="text"
              name="occupation"
              value={values?.occupation}
              border="1px solid #707070"
              borderRadius="5px"
              height="5px"
              handleChange={(e) => {
                handleInputDataChange(e);
              }}
            />
          </Box>
        </Grid>

        <Grid item lg={5} md={5} sm={5}>
          <Box>
            <CustomLabel label="ID Type *" />

            <CustomSelectValue
              disable={fieldDisable || values.nationality == "foriegner"}
              opacity={fieldDisable ? 0.4 : 1}
              name="id_proof_type"
              border="1px solid #707070"
              borderRadius="5px"
              padding="4px 12px 5px"
              muiOptions={idProofTypes}
              value={values?.id_proof_type}
              handleChange={(e) => {
                handleInputDataChange(e);
                if (e.target.value == "Others") {
                  setIdproofFlg(true);
                } else {
                  setIdproofFlg(false);
                }
                if (e.target.value == "Passport") {
                  setIdPassportFlg(true);
                } else {
                  setIdPassportFlg(false);
                }
              }}
            />
          </Box>

          {idPassportFlg && (
            <Box>
              <CustomLabel label="Passport No *" />
              <CustomTextField
                disabled={fieldDisable}
                opacity={fieldDisable ? 0.4 : 1}
                placeholder="Passport No"
                name="id_type_passport"
                border="1px solid #707070"
                borderRadius="5px"
                height="5px"
                value={values?.id_type_passport}
                handleChange={(e) => {
                  handleInputDataChange(e);
                }}
              />
            </Box>
          )}

          {idproofFlg && (
            <Box>
              <CustomLabel label="ID Type Comment *" />
              <CustomTextField
                disabled={fieldDisable}
                opacity={fieldDisable ? 0.4 : 1}
                placeholder="comment"
                name="id_type_comment"
                border="1px solid #707070"
                borderRadius="5px"
                height="0px"
                value={values?.id_type_comment}
                handleChange={(e) => {
                  handleInputDataChange(e);
                }}
              />
            </Box>
          )}

          <Box>
            <CustomLabel label="Booking Source *" />
            <CustomSelectValue
              disable={fieldDisable}
              opacity={fieldDisable ? 0.4 : 1}
              border="1px solid #707070"
              padding="4px 12px 5px"
              name="booking_source"
              value={values?.booking_source}
              borderRadius="5px"
              muiOptions={booking_source_values}
              handleChange={(e) => {
                handleInputDataChange(e);
              }}
            />
          </Box>
          <Box marginTop={2}>
            <CustomLabel label="Purpose of Visit *" />
            <CustomSelectValue
              disable={fieldDisable}
              opacity={fieldDisable ? 0.4 : 1}
              border="1px solid #707070"
              padding="4px 12px 5px"
              name="purpose_of_visit"
              value={values?.purpose_of_visit}
              borderRadius="5px"
              muiOptions={purpose_of_visit_values}
              handleChange={(e) => {
                handleInputDataChange(e);
                if (e.target.value == "Others") {
                  setPurposeCmntFlg(true);
                } else {
                  setPurposeCmntFlg(false);
                }
              }}
            />
          </Box>

          {purposeCmntFlg && (
            <Box>
              <CustomLabel label="POV Comment *" />

              <CustomTextField
                disabled={fieldDisable}
                opacity={fieldDisable ? 0.4 : 1}
                placeholder="comment"
                name="purpose_of_visit_comment"
                border="1px solid #707070"
                value={values?.purpose_of_visit_comment}
                borderRadius="5px"
                height="0px"
                handleChange={(e) => {
                  handleInputDataChange(e);
                }}
              />
            </Box>
          )}

          <Box marginTop={2}>
            {/* <CustomLabel label="Room Details *" /> */}
            {[...Array(roomCnt)].map((elementInArray, index) => (
              <Box
                sx={{
                  color: "white",

                  marginBottom: "12px",
                }}
                key={index}
              >
                <CustomLabel label={`Room Details ${index + 1}`} />
                <Box
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <CustomSelectValue
                    disable={fieldDisable || editFlag}
                    opacity={fieldDisable ? 0.4 : 1}
                    placeholder={"Room Type"}
                    id="room-det"
                    border="1px solid #707070"
                    // label="Room Type"
                    defaultValue=""
                    borderRadius="5px"
                    // disable={!!room_no}
                    padding="5px 12px 4px 12px"
                    marginRight="5px"
                    value={
                      values?.rooms_details?.length > 0
                        ? values?.rooms_details[index]?.type
                        : ""
                    }
                    name="name"
                    muiOptions={roomMuiOpt ?? ""}
                    handleChange={(e) => {
                      handleChangeRoomtype(e, index);
                    }}
                  />

                  <Box></Box>
                  <CustomSelect
                    disable={fieldDisable || editFlag}
                    opacity={fieldDisable ? 0.4 : 1}
                    placeholder="Room Number"
                    style={{ position: "relative" }}
                    id="room-det"
                    border="1px solid #707070"
                    defaultValue=""
                    borderRadius="5px"
                    // disable={!!room_no}
                    value={values?.rooms_details[index]?.value}
                    name="room_number"
                    padding="5px 12px 4px 12px"
                    marginRight="5px"
                    muiOptions={values?.rooms_details[index]?.values ?? ""}
                    handleChange={(e) => {
                      handleChangeRoomtypeValue(e, index);
                    }}
                  />
                  {!editFlag && (
                    <AddCircleRoundedIcon
                      style={{
                        color: "#E98D12",
                        marginTop: "6px",
                        position: "absolute",
                        right: "8%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (roomCnt < 15 && validateCurrentRoomStats()) {
                          let newvalues = { ...values };
                          newvalues?.rooms_details.push({
                            type: "",
                            values: [],

                            no_of_persons: 0,
                            check_in_date_time: new Date(),
                            check_out_date_time: null,
                          });
                          setValues({ ...newvalues });

                          setRoomCnt(roomCnt + 1);
                          if (roomCnt + 1 > 1) {
                            setRemoveIcnFlg(true);
                          } else {
                            setRemoveIcnFlg(false);
                          }
                        } else {
                          setFillErr(true);
                        }
                      }}
                    />
                  )}
                  {removeIcnFlg && (
                    <RemoveCircleRoundedIcon
                      style={{
                        color: "#E98D12",
                        marginTop: "6px",
                        position: "absolute",
                        right: "5%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (roomCnt > 1) {
                          let newvalues = { ...values };

                          const indexOfObject =
                            newvalues?.rooms_details?.findIndex((object) => {
                              if (
                                object?.type ===
                                values?.rooms_details[index]?.type &&
                                object?.values?.length == 0
                              ) {
                                return true;
                              } else if (
                                object?.type ===
                                values?.rooms_details[index]?.type &&
                                object?.values[0].id ===
                                values?.rooms_details[index]?.values[0].id
                              ) {
                                return true;
                              }
                            });

                          newvalues?.rooms_details.splice(index, 1);

                          setValues({ ...newvalues });
                          setRoomCnt(roomCnt - 1);
                          if (roomCnt - 1 == 1) {
                            setRemoveIcnFlg(false);
                          } else {
                            setRemoveIcnFlg(true);
                          }
                        } else {
                          setRemoveIcnFlg(false);
                        }
                      }}
                    />
                  )}
                </Box>
                <Box style={{ display: "flex", gap: "4px" }}>
                  <Box id={styles.nopbox} padding="0px" margin="0px">
                    <CustomLabel label="No of Persons*" />

                    <Box>
                      <TextField
                        name="no_of_persons"
                        type="text"
                        disabled={fieldDisable}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            height: "38px",
                            width: "100%",
                          },
                          background: "#FFFFFF",
                          opacity: `${fieldDisable ? 0.4 : 1}`,
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          let data = { ...values };
                          let val = e.target.value;
                          val = val.replace(/[^\w ]/g, "");
                          val = val.replace(/^\s+|\s+$/gm, "");
                          if (val?.length >= 2) {
                            val = val.substr(0, 2);
                          }
                          const re = /^[0-9\b]+$/;

                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            data.rooms_details[index].no_of_persons =
                              Number(val);
                          }

                          setValues({ ...data });
                        }}
                        placeholder="0"
                        variant="outlined"
                        value={values?.rooms_details[index]?.no_of_persons}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  let data = { ...values };
                                  let room_stat = data?.rooms_details;
                                  if (room_stat[index].no_of_persons < 99) {
                                    room_stat[index].no_of_persons++;
                                  }

                                  setValues({
                                    ...data,
                                    rooms_details: room_stat,
                                  });
                                  setFillErr(false);
                                }}
                                sx={{ padding: "0px", margin: "0px" }}
                              >
                                <AddIcon
                                  id={styles.orange}
                                  sx={{
                                    cursor: "pointer",
                                    color: CLR_ORANGE,
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  if (
                                    values?.rooms_details[index]
                                      ?.no_of_persons >= 1
                                  ) {
                                    let data = { ...values };
                                    let room_stat = data?.rooms_details;
                                    room_stat[index].no_of_persons--;
                                    setValues({
                                      ...data,
                                      rooms_details: room_stat,
                                    });
                                  }
                                }}
                                sx={{ padding: "0px", margin: "0px" }}
                              >
                                <RemoveIcon
                                  id={styles.orange}
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "15px",
                                    padding: "0px",
                                    fontSize: "16px",
                                    color: CLR_ORANGE,
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    style={{ width: "55.4%", padding: "0px", margin: "0px" }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        margin: "12px 0px 12px 0px",
                        fontWeight: "bold",
                      }}
                    >
                      Check-in date & time*
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDateTimePicker
                        disabled={Number(USER_DATA?.role) === 1 ? fieldDisable : true}
                        value={values?.rooms_details[index]?.check_in_date_time}
                        ampm={true}
                        disableFuture={true}
                        disablePast={true}
                        maxTime={new Date()}
                        onChange={(newValue) => {
                          let data = { ...values };
                          let room_stats = data?.rooms_details;

                          room_stats[index].check_in_date_time = newValue;

                          setValues({
                            ...data,
                            room_details: room_stats,
                          });
                        }}
                        name="check_in_date_time"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              opacity: `${fieldDisable ? 0.4 : 1}`,
                              "& .MuiOutlinedInput-input": { height: "5px" },

                              "& .MuiInputBase-input": {},
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #707070",
                                borderRadius: "5px",
                                borderColor: "black!important",
                              },
                            }}
                            style={{
                              background: "#FFFFFF",
                              borderRadius: "5px",
                              border: "none",
                              borderColor: "black",
                              width: "100%",
                            }}
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={3} justifyContent="center" paddingTop={8}>
        {draftFlag && (
          <>
            <Button
              variant="contained"
              disabled={buttonDisabled}
              onClick={() => {
                setBookingStatus("arrival_draft");
                validateForm("arrival_draft");
                setResultLabel("Saved as draft successfull !");
              }}
              id={styles.save_draft_button}
            >
              Save as Draft
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setFillErr(false);
                setClrDlgOpn(true);
                setResultLabel("Are you sure to clear all the info?");
              }}
              id={styles.clear_button}
            >
              Clear
            </Button>
          </>
        )}
        <Button
          variant="contained"
          type="submit"
          disabled={buttonDisabled}
          onClick={() => {
            validateForm("arrival");
          }}
          id={styles.save_button}
        >
          Save
        </Button>
      </Stack>

      {open && (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{
            "& .MuiPaper-root": {
              background: CLR_DLG_WHITE,
              borderRadius: "20px",
              height: "60%",
              width: "76%",
            },
            "& .MuiDialogContent-root": {
              border: "none",
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {viewFlag && (
              <Typography sx={{ color: CLR_ORANGE }}>Take Photo</Typography>
            )}
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <Tooltip title="Close">
                <img
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  src="/images/icon_close_circle_orange.png"
                  onClick={(item) => {
                    setSource(null);
                    setOpen(false);
                  }}
                />
              </Tooltip>
              {/* <CloseIcon /> */}
            </IconButton>
          </DialogTitle>
          {loading === true ? (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                zIndex: "100",
                textAlign: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <div className={styles.loader}></div>
            </div>
          ) : null}

          <DialogContent dividers>
            <Box
              style={{
                justifyContent: "center",
                marginLeft: "20px",
                marginRight: "20px",
                position: "relative",
                display: "flex",
                height: "93%",
                width: "90%",
              }}
            >
              {source ? (
                <Box
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={source}
                    style={{ width: "100%", height: "100%" }}
                    alt={"snap"}
                  ></img>
                </Box>
              ) : (
                <Box
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="images/img_enlarged_logo.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      background: CLR_LIGHT_GREY,
                    }}
                    alt={"snap"}
                  ></img>
                </Box>
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",

                  top: "255px",
                  position: "absolute",
                }}
              >
                <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  ref={fileUpload}
                  onChange={(e) => handleCapture(e.target)}
                  style={{ display: "none" }}
                />
              </Box>
            </Box>
          </DialogContent>
          {viewFlag && (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={1}
              sx={{
                paddingBottom: "25px",
                marginTop: "-35px",
              }}
            >
              <Button
                disabled={!source}
                sx={{
                  paddingLeft: "10px",
                  marginRight: "-60px",
                  paddingRight: "10px",
                  background: `${CLR_LIGHT_WHITE}`,
                  borderRadius: "10px",
                  shadowColor: "1px 1px 6px #E98D12",
                  color: `${CLR_ORANGE}`,
                  "&:hover": {
                    color: `${CLR_ORANGE}`,
                    background: `${CLR_LIGHT_WHITE}`,
                  },
                }}
                variant="contained"
                // onClick={scanOcr}
                onClick={() => {
                  // setAsOcr(true);
                  scanOcr(true);
                }}
              >
                {ocrScan ? "Save as ID" : "Save as Image"}
              </Button>

              {ocrScan ? (
                <Button
                  disabled={!source}
                  sx={{
                    paddingLeft: "10px",
                    marginRight: "-60px",
                    paddingRight: "10px",
                    background: `${CLR_LIGHT_WHITE}`,
                    borderRadius: "10px",
                    shadowColor: "1px 1px 6px #E98D12",
                    color: `${CLR_ORANGE}`,
                    "&:hover": {
                      color: `${CLR_ORANGE}`,
                      background: `${CLR_LIGHT_WHITE}`,
                    },
                  }}
                  variant="contained"
                  // onClick={scanOcr}
                  onClick={() => {
                    // setAsOcr(false);
                    scanOcr(false);
                  }}
                >
                  {"Save as Image"}
                </Button>
              ) : (
                ""
              )}

              <Button
                variant="contained"
                sx={{
                  paddingLeft: "10px",
                  marginLeft: "-50px",
                  paddingRight: "10px",
                  background: `${CLR_ORANGE}`,
                  borderRadius: "10px",
                  shadowColor: "1px 1px 6px #E98D12",
                  "&:hover": {
                    color: `${CLR_ORANGE}`,
                    background: `${CLR_LIGHT_WHITE}`,
                  },
                }}
                onClick={() => {
                  if (ocrScan && source) {
                    let newvalues = [...values.id_datas];
                    let finalData = newvalues.splice(
                      values?.id_datas.length - 2,
                      1
                    );
                    setValues({ ...values, id_datas: newvalues });
                  }
                  setIdCnt(idCnt - 1);
                  setSource(null);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Dialog>
      )}

      <SignatureDiologue
        open={signDlgOpn}
        setSignFile={setSignFile}
        setSignImage={setSignImage}
        setOpen={setSignDlgOpn}
        signImage={signImage}
        label={resultLabel}
      />

      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label={resultLabel}
        width="400px"
        height="150px"
      />
      <ClearFormDiologueBox
        open={clrDlgOpn}
        clearForm={clearForm}
        setOpen={setClrDlgOpn}
        label={resultLabel}
      />
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={tstContent}
      />
      <Box width={"100vw"} sx={{ display: "flex", alignItems: "center" }}></Box>
    </Box>
  );
}

export default BookingForm;
