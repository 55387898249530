import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  css,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import CustomTextField from "../../components/CustomTextField";
import styles from "./departure.module.css";
import SearchIcon from "@mui/icons-material/Search";
import CustomLabel from "../../components/CustomLabel";
import CustomSelect from "../../components/CustomSelect";
import ImageDiologueBox from "../../components/ImageDiologueBox";
import ReactSignatureCanvas from "react-signature-canvas";
import CanvasDraw from "react-canvas-draw";
import imageCompression from "browser-image-compression";
import Scanner from "react-webcam-qr-scanner";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { useNavigate, useParams } from "react-router-dom";
import Appbar from "../../components/AppBar/Appbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import CreatableSelect from "react-select/creatable";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import CustomSelectValue from "../../components/CustomSelectValue";
import {
  CLR_DLG_WHITE,
  CLR_LIGHT_GREY,
  CLR_LIGHT_WHITE,
  CLR_ORANGE,
} from "../../utils/colors";
import ErrorField from "../../components/ErrorField";
import ResultDiologueBox from "../../components/ResultDiologueBox";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { addEditBooking } from "../../redux/action/booking.action";
import ClearRoomDiologueBox from "../../components/ClearRoomDiologueBox";
import SignatureDiologue from "../../components/Diologues/SignatureDiologue";
import { trackPromise } from "react-promise-tracker";
import { getRoomStatics } from "../../services/roomSevices";
import {
  getCityListData,
  getCountryListData,
  getStateListData,
} from "../../services/utilsServices";
import {
  addEditDepartureData,
  getBookingsDetailsData,
  getDepartureDetailsData,
} from "../../services/bookingServices";
import CustomSnackBar from "../../components/CustomSnackBar";
import Compressor from "compressorjs";
import CustomSaveButton from "../../components/CustomSaveButton";
import CustomCancelButton from "../../components/CustomCancelButton";
import staticTexts from "../../utils/static-texts";

function DepartureForm() {
  const dispatch = useDispatch();
  let fileupload = useRef(null);
  const selectInputRef = useRef(null);

  const idProofTypes = [
    { id: "1", value: "Aadhar Card" },
    { id: "2", value: "PassPort" },
    { id: "3", value: "Driving Licence" },
    { id: "4", value: "Voter ID" },
    { id: "5", value: "Others" },
  ];

  const USER_DATA = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [idImage, setIdImage] = useState(null);
  const [bookingId, setBookingId] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [qrdata, setQrData] = useState(null);
  const [qrDlg, setQrDlg] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [personCnt, setpersonCnt] = useState(0);
  const [roomCnt, setRoomCnt] = useState(1);

  const [searchVal, setSearchVal] = useState(null);
  const [searchError, setSearchError] = useState(false);

  const [resDlgOpn, setResDlgOpn] = useState(false);
  const [resultLabel, setResultLabel] = useState(null);

  const [ocrScan, setOcrScan] = useState(false);
  const [ocrFile, setOcrFile] = useState(null);
  const [customerId, setCustomerId] = useState(0);

  const [bookingStatus, setbBookingStatus] = useState(null);
  const [clrDlgOpn, setClrDlgOpn] = useState(false);

  const [searchBoxFlag, setSearchBoxFlag] = useState(true);
  const [visibleFlag, setVisibleFlag] = useState(false);

  const [roomMuiOpt, setRoomMuiOpt] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [removeIcnFlg, setRemoveIcnFlg] = useState(false);
  const [signDlgOpn, setSignDlgOpn] = useState(false);
  const [signImage, setSignImage] = useState(null);
  const [signFile, setSignFile] = useState(null);

  const [purposeCmntFlg, setPurposeCmntFlg] = useState(false);
  const [bookingsrcCmntFlg, setBookingsrcCmntFlg] = useState(false);
  const [idproofFlg, setIdproofFlg] = useState(false);

  const [indianId, setIndianId] = useState(null);

  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);
  const [disableFlg, setDisabeFlg] = useState(true);
  const [idPassportFlg, setIdPassportFlg] = useState(false);

  const [idCnt, setIdCnt] = useState(1);
  const [crntIdIndex, setCrntIdIndex] = useState(-1);

  const [radClr, setradClr] = useState("white");
  const navigate = useNavigate();

  const [rmNo, setRmNo] = useState("");

  let sigCanvas = useRef({});

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const initialFvalues = {
    id_proof_type: "",
    name: "",
    mobile: "",
    altranate_mobile: "",
    address: "",
    city: "",
    state: "",
    country: "",
    occupation: "",
    booking_source: "",
    check_in_date_time: "",

    no_of_persons: 0,
    no_of_rooms: 0,
    rooms_details: [
      {
        type: "",
        values: [],
        value: "",
        no_of_persons: 0,
        check_in_date_time: null,
        check_out_date_time: null,
        room_line_id: "",

      },
    ],
    purpose_of_visit: "",
    signature: "",
    id_image: "",
    cust_image: "",
    id_image_url: "",
    cust_image_url: "",
    signature_image_url: "",
    nationality: "indian",
    heading_to: "",
    // receipt_request: "",
    purpose_of_visit_comment: "",
    id_type_comment: "",
    id_type_passport: "",
    data_upload: "",
    id_datas: [{ file: "", url: "" }],
  };

  let initialErrors = {
    id_proof_type: "",
    name: "",
    mobile: "",
    altranate_mobile: "",
    address: "",
    state: "",
    country: "",
    occupation: "",
    booking_source: "",
    check_in_date_time: "",

    no_of_persons: "",
    no_of_rooms: "",
    rooms_details: [
      {
        type: "",
        values: [],
        value: "",
        no_of_persons: 0,
        check_in_date_time: null,
        check_out_date_time: null,
      },
    ],
    purpose_of_visit: "",
    signature: "",
    room_details_data: "",
    signature: "",
    id_image: "",
    cust_image: "",
    id_image_url: "",
    cust_image_url: "",
    signature_image_url: "",
    nationality: "",
    // receipt_request: "",
    heading_to: "",
    purpose_of_visit_comment: "",
    id_type_comment: "",
    id_type_passport: "",
    data_upload: "",
    id_datas: [{ file: "", url: "" }],
  };

  const [errors, setErrors] = useState({ ...initialErrors });

  const [values, setValues] = useState(initialFvalues);

  const handleInputDataChange = (e) => {
    let { name, value } = e.target;

    if (name == "purpose_of_visit" && value == "Others") {
      setPurposeCmntFlg(true);
    } else if (name == "id_proof_type" && value == "Others") {
      setIdproofFlg(true);
    }

    if (
      name == "mobile" &&
      value.length >= 10 &&
      values.nationality == "indian"
    ) {
      value = value.substr(0, 10);
    }

    if (
      name == "altranate_mobile" &&
      value.length >= 10 &&
      values.nationality == "indian"
    ) {
      value = value.substr(0, 10);
    }
    const re = /^[0-9\b]+$/;

    if (name == "mobile" || name == "altranate_mobile") {
      if (e.target.value === "" || re.test(e.target.value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }

    if (name == "heading_to") {
      let val = value;
      val = val.replace(/[^\w ]/g, "");
      val = val.replace(/^\s+|\s+$/gm, "");
      if (val === "" || re.test(val)) {
        setValues({ ...values, [name]: val });
      }
    }
  };

  const handleNationalityChange = (e) => {
    let { name, value } = e.target;

    if (value == "indian") {
      setValues({
        ...values,
        [name]: value,
        country: { id: indianId, label: "India" },
        id_proof_type: "",
        state: "",
        name: "",
        id_type_passport: "",
        mobile: "",
        altranate_mobile: "",
        address: "",
        city: "",
        occupation: "",
      });

      setIdPassportFlg(false);
      getStateList(indianId);
    } else {
      setValues({
        ...values,
        [name]: value,
        country: "",
        state: "",
        city: "",
        mobile: "",
        name: "",
        altranate_mobile: "",
        occupation: "",
        address: "",
        id_proof_type: "PassPort",
      });

      setStates([]);
      setCities([]);
      setIdproofFlg(false);
      setIdPassportFlg(true);
    }
  };

  const citystyles = {
    menu: (base) => ({
      ...base,
      marginTop: 0,
    }),
  };
  useEffect(() => {
    let newerrors = { ...errors };
    Object.keys(values).map((key) => {
      if (values[key]) {
        newerrors[key] = "";
      }
    });

    setErrors(newerrors);
  }, [values]);

  const scanOcr = () => {
    if (ocrFile) {
      let FormData = require("form-data");
      let ocrData = new FormData();
      ocrData.append("proof_image", ocrFile);
      var base64_data;
    }
  };

  const [arr, setArr] = useState([]);
  const params = new URLSearchParams(document.location.search);
  const booking_id = new URLSearchParams(document.location.search).get(
    "booking_id"
  );
  const room_no = new URLSearchParams(document.location.search).get("room_no");
  useEffect(() => {
    if (booking_id > 0 && booking_id !== undefined) {
      setSearchBoxFlag(false);
      setVisibleFlag(true);
      setEditFlag(true);
    } else {
      setEditFlag(false);
    }
    getCountryList();
    getRoomDetailsStatics();
  }, []);
  const getRoomDetailsStatics = async (val) => {
    let id = null;
    if (booking_id > 0 && booking_id !== undefined) {
      id = booking_id;
    } else {
      id = 0;
    }

    await trackPromise(
      getRoomStatics()
        .then((res) => {
          let { data, error } = res?.data;
          let dataArr = [];
          let muiArr = [];

          data?.map((item) => {
            let obj = {};
            let muiObj = {};
            obj.type = item?.type;
            obj.values = JSON.parse(item?.values);
            muiObj.value = item?.type;
            dataArr.push(obj);
            muiArr.push(muiObj);
          });

          setArr(dataArr);
          setRoomMuiOpt(muiArr);

          if (booking_id > 0 && booking_id !== undefined) {
            getBookingDetails(booking_id, dataArr);
          }
        })
        .catch((err) => { })
    );
  };

  const getBookingDetails = async (bookingId, arrayData) => {
    setBookingId(bookingId);
    await trackPromise(
      getBookingsDetailsData(bookingId, room_no)
        .then((res) => {
          let { data, error } = res?.data;
          let room_stats = [];
          let new_rooms = [];
          let presentRoomsArr = [];

          if (data) {
            data?.room_lines?.map((dataItem) => {
              dataItem.checkout_date = null;

              arrayData?.map((item) => {
                if (item?.type == dataItem?.room_type_name) {
                  new_rooms = [];

                  let val = item?.values;
                  val?.map((data) => {
                    new_rooms.push({
                      value: data?.room__number,
                      id: data?.room_type_id,
                    });
                  });
                  room_stats.push({
                    type: dataItem?.room_type_name,
                    values: new_rooms,
                    value: dataItem?.room_type_id,
                    no_of_persons: dataItem?.no_of_persons,
                    check_in_date_time: dataItem?.checkin_date,
                    check_out_date_time: dataItem?.checkout_date,
                  });
                }
              });
              presentRoomsArr.push(dataItem?.room_no);
            });

            let muiArr = [];
            let newArrayData = [];
            newArrayData = arrayData;

            arrayData?.map((item, index) => {
              let val = item?.values;
              let tempval = [];
              val?.map((data) => {
                if (
                  data?.room_availability != "Occupied" ||
                  presentRoomsArr.includes(data?.room__number)
                ) {
                  tempval.push(data);
                }
              });
              if (tempval?.length > 0) {
                let muiObj = {};
                muiObj.value = item?.type;

                muiArr.push(muiObj);
              }
              newArrayData[index].values = tempval;
            });
            setArr(newArrayData);
            setRoomMuiOpt(muiArr);

            let id_data_array = [];
            if (data?.id_proof_image_url != "") {
              data?.id_proof_image_url?.map((item) => {
                let obj = {};
                obj.file = item?.key;
                obj.url = item?.url;
                id_data_array.push(obj);
              });
            } else {
              id_data_array = [{ file: "", url: "" }];
            }

            if (
              id_data_array?.length >= 1 &&
              data?.id_proof_image_url &&
              data?.id_proof_image_url != ""
            ) {
              id_data_array.push({ file: "", url: "" });
            }

            const new_values = {
              id_proof_type: data?.id_proof,
              id_datas: id_data_array,
              altranate_mobile: data?.altranate_mobile,
              name: data?.name,
              mobile: data?.mobile,
              address: data?.address,
              city: { id: data?.city_id, label: data?.city_name },
              state:
                data?.state_id != null
                  ? { label: data?.state_name, id: data?.state_id }
                  : null,

              booking_source: data?.booking_source,
              check_in_date_time: data?.checked_in,
              check_out_date_time: data?.checkout_in,
              no_of_persons: Number(data?.no_of_persons),
              no_of_rooms: data?.room_lines?.length,
              rooms_details: room_stats,
              heading_to: data?.room_lines?.[0].heading_to,
              purpose_of_visit: data?.purpose_of_visit,
              booking_id: data?.booking_id,
              occupation: data?.occupation,
              cust_image: data?.cust_image,
              id_image: data?.id_proof_image,
              signature: data?.signature_image,
              nationality: data?.nationality,
              country: {
                id: Number(data?.country_id),
                label: data?.country_name,
              },

              purpose_of_visit_comment: data?.purpose_of_visit_comment,
              id_type_comment: data?.id_type_comment,
              id_type_passport: data?.passport_no,
            };

            if (booking_id > 0 && booking_id !== undefined) {
              getStateList(data?.country_id);
            }

            setIdImage(data?.id_proof_image_url);
            setImage(data?.cust_image_url);
            setSignImage(data?.departure_signature_image_url);

            setRoomCnt(data?.room_lines?.length);
            if (data?.room_lines?.length > 1) {
              setRemoveIcnFlg(true);
            } else {
              setRemoveIcnFlg(false);
            }

            if (new_values?.purpose_of_visit == "Others") {
              setPurposeCmntFlg(true);
            }
            if (new_values?.id_proof_type == "Others") {
              setIdproofFlg(true);
            }
            if (new_values?.id_proof_type == "PassPort") {
              setIdPassportFlg(true);
            }

            setValues({ ...values, ...new_values });
            setBookingId(data.booking_id);
          }
        })
        .catch((err) => { })
    );
  };

  const getCountryList = async () => {
    await trackPromise(
      getCountryListData()
        .then((res) => {
          let { data } = res?.data;
          let newCountries = data?.map((d) => {
            return {
              id: d.id,
              value: d.value,
            };
          });

          let countryId;
          data?.map((d) => {
            if (d.value == "India") countryId = d.id;
          });
          setIndianId(countryId);

          setCountries(newCountries);
          if (booking_id > 0 && booking_id !== undefined) {
          } else {
            setValues({
              ...values,
              country: countryId,
            });

            getStateList(countryId);
          }
        })
        .catch((err) => { })
    );
  };

  const getStateList = async (country_id) => {
    await trackPromise(
      getStateListData(country_id)
        .then((res) => {
          let { data } = res?.data;

          let newStates = data?.map((d) => {
            return {
              id: d.id,
              label: d.value,
            };
          });

          setStates(newStates);
        })
        .catch((err) => { })
    );
  };

  const getCityList = async (state_id) => {
    await trackPromise(
      getCityListData(state_id)
        .then((res) => {
          let { data } = res?.data;

          setCities(data);
        })
        .catch((err) => { })
    );
  };

  const vaidation = (name, value) => {
    let newerrors = errors;

    switch (name) {
      case "name":
        newerrors.name = value.length < 1 ? "This Field is Required" : "";
        break;

      case "mobile":
        newerrors.mobile =
          value.length < 8 ? "mobile must be at least 8 characters long!" : "";
        break;
      case "address":
        newerrors.address = value.length < 1 ? "This Field is Required" : "";
        break;
      case "city":
        newerrors.city = value.length < 1 ? "This Field is Required" : "";
        break;
      case "state":
        newerrors.state = value.length < 1 ? "This Field is Required" : "";
        break;
      case "occupation":
        newerrors.occupation = value.length < 1 ? "This Field is Required" : "";
        break;
      case "booking_source":
        newerrors.booking_source =
          value.length < 1 ? "This Field is Required" : "";
        break;
      case "check_in_date_time":
        newerrors.check_in_date_time =
          value.length < 1 ? "This Field is Required" : "";
        break;
      case "check_out_date_time":
        newerrors.check_out_date_time =
          value.length < 1 ? "This Field is Required" : "";
        break;
      case "no_of_persons":
        newerrors.no_of_persons =
          value.length < 1
            ? "This Field is Required and Must be greater than 0"
            : "";
        break;
      case "no_of_rooms":
        newerrors.no_of_rooms =
          value.length < 1
            ? "This Field is Required and Must be greater than 0"
            : "";
        break;
      case "purpose_of_visit":
        newerrors.purpose_of_visit =
          value.length < 1 ? "This Field is Required " : "";
        break;
      case "heading_to":
        newerrors.heading_to =
          value.length < 1 ? "This Field is Required " : "";
        break;

      default:
        break;
    }

    setErrors({ ...newerrors });
  };

  const handleChangeRoomtypeValue = (e, index) => {
    if (e.target.value) {
      let data = { ...values };

      var roomTypeId = 0;

      data?.rooms_details[index].values.map((item) => {
        if (item?.id == e.target.value) {
          roomTypeId = item?.id;
        }
      });
      data.rooms_details[index].id = roomTypeId;
      data.rooms_details[index].value = e.target.value;
      setValues(data);
    }
  };

  const handleRoomStatsSearch = async (val) => {
    await trackPromise(
      axios
        .get(`${process.env.REACT_APP_API_URL}/rooms/getRoomstats`)
        .then((res) => {
          let { data, error } = res?.data;

          let dataArr = [];
          let muiArr = [];

          data?.map((item) => {
            let obj = {};
            let muiObj = {};
            obj.type = item?.type;
            obj.values = JSON.parse(item.values);
            muiObj.value = item?.type;
            dataArr.push(obj);
            muiArr.push(muiObj);
          });

          setArr(dataArr);
          setRoomMuiOpt(muiArr);

          if (booking_id > 0 && booking_id !== undefined) {
            getBookingDetails(booking_id, dataArr);
          }
        })
        .catch((err) => { })
    );
  };
  const handleSearch = async () => {
    setSearchError(false);
    await trackPromise(
      getDepartureDetailsData({
        mobile_no: searchVal,
      })
        .then((res) => {
          let { data, error } = res?.data;

          let room_stats = [];
          let new_rooms = [];
          let presentRoomsArr = [];

          if (data) {
            data?.room_lines.map((dataItem) => {
              arr?.map((item) => {
                if (item?.type == dataItem?.room_type_name) {
                  new_rooms = [];

                  let val = item?.values;
                  val?.map((data) => {
                    new_rooms.push({
                      value: data?.room__number,
                      id: data?.room_type_id,
                    });
                  });

                  if (
                    (booking_id > 0 && booking_id !== undefined) ||
                    data?.mobile === searchVal
                  ) {
                    room_stats.push({
                      type: dataItem?.room_type_name,
                      values: new_rooms,
                      value: dataItem?.room_type_id,
                      no_of_persons: dataItem?.no_of_persons,
                      check_in_date_time: dataItem?.checkin_date,
                      check_out_date_time: dataItem?.checkout_date
                        ? dataItem?.checkout_date
                        : new Date(),
                      lineEditFlag: dataItem?.checkout_date ? true : false,
                      room_line_id: dataItem?.room_line_id,
                    });
                  } else {
                    if (searchVal == dataItem?.room_no) {
                      room_stats.push({
                        type: dataItem?.room_type_name,
                        values: new_rooms,
                        value: dataItem?.room_type_id,
                        no_of_persons: dataItem?.no_of_persons,
                        room_line_id: dataItem?.room_line_id,
                        check_in_date_time: dataItem?.checkin_date,
                        check_out_date_time: dataItem?.checkout_date
                          ? dataItem?.checkout_date
                          : new Date(),
                        lineEditFlag: dataItem?.checkout_date ? true : false,
                      });
                    }
                  }
                }
              });
              presentRoomsArr.push(dataItem?.room_no);
            });

            let newArrayData = [];
            newArrayData = arr;
            let muiArr = [];

            arr.map((item, index) => {
              let val = item?.values;
              let tempval = [];
              val.map((data) => {
                if (
                  data?.room_availability != "Occupied" ||
                  presentRoomsArr.includes(data?.room__number)
                ) {
                  tempval.push(data);
                }
              });
              if (tempval?.length > 0) {
                newArrayData[index].values = tempval;
                let muiObj = {};
                muiObj.value = item?.type;

                muiArr.push(muiObj);
              }
            });

            setArr(newArrayData);
            setRoomMuiOpt(muiArr);

            let id_data_array = [];
            if (data?.id_proof_image_url != "") {
              data?.id_proof_image_url?.map((item) => {
                let obj = {};
                obj.file = item.key;
                obj.url = item.url;
                id_data_array.push(obj);
              });
            } else {
              id_data_array = [{ file: "", url: "" }];
            }
            if (
              id_data_array?.length >= 1 &&
              data?.id_proof_image_url &&
              data?.id_proof_image_url != ""
            ) {
              id_data_array.push({ file: "", url: "" });
            }

            const new_values = {
              id_proof_type: data?.id_proof,
              id_datas: id_data_array,
              altranate_mobile: data?.altranate_mobile,
              name: data?.name,
              mobile: data?.mobile,
              address: data?.address,
              city: { id: data?.city_id, label: data?.city_name },

              state: { label: data?.state_name, id: data?.state_id },
              booking_source: data?.booking_source,
              check_in_date_time: data?.checked_in,
              check_out_date_time: data?.checkout_in,
              no_of_persons: Number(data?.no_of_persons),
              no_of_rooms: data.room_lines?.length,
              rooms_details: room_stats,
              heading_to: data?.heading_to,
              purpose_of_visit: data?.purpose_of_visit,
              booking_id: data?.booking_id,
              occupation: data?.occupation,
              cust_image: data?.cust_image,
              id_image: data?.id_proof_image,
              signature: data?.signature_image,
              nationality: data?.nationality,

              country: {
                id: Number(data?.country_id),
                label: data?.country_name,
              },
              purpose_of_visit_comment: data?.purpose_of_visit_comment,
              id_type_comment: data?.id_type_comment,
              id_type_passport: data?.passport_no,
            };

            if (booking_id > 0 && booking_id !== undefined) {
              getStateList(data?.country_id);
            }
            setCustomerId(data?.customer_id);
            setIdImage(data?.id_proof_image_url);
            setImage(data?.cust_image_url);

            setSignImage(data?.departure_signature_image_url);

            setRoomCnt(room_stats?.length);

            if (room_stats?.length > 1) {
              setRemoveIcnFlg(true);
            } else {
              setRemoveIcnFlg(false);
            }
            if (new_values?.purpose_of_visit == "Others") {
              setPurposeCmntFlg(true);
            }
            if (new_values?.id_proof_type == "Others") {
              setIdproofFlg(true);
            }
            if (new_values?.id_proof_type == "PassPort") {
              setIdPassportFlg(true);
            }

            setValues({ ...values, ...new_values });
            setBookingId(data?.booking_id);
            setVisibleFlag(true);
            setSearchBoxFlag(false);
          } else {
            setTstContent(error);
            setTstOpn(true);
          }
        })
        .catch((err) => {
          if (err?.response) {
            setTstContent(err?.response?.data?.error);
            setTstOpn(true);
          }
        })
    );
  };

  const clearForm = () => {
    setValues({
      ...initialFvalues,
      country:
        values?.country /*values.nationality === "indian"? {country : values.country} : null */,
    });

    setSignFile(null);
    setOcrFile(null);
    setImageFile(null);

    setIdImage(null);
    setImage(null);
    setSignImage(null);

    setErrors({ ...initialErrors });
    setRoomCnt(1);
    let newerrors = { ...initialFvalues };
  };

  const [viewFlag, setViewFlag] = useState(true);
  const [source, setSource] = useState(null);
  const fileUpload = useRef(null);

  const handleCapture = async (target) => {
    if (ocrScan) {
      let newvalues = { ...values };
      let currentVal = newvalues?.id_datas;
      currentVal.push({
        file: "",
        url: "",
      });
      setValues({
        ...newvalues,
        id_datas: currentVal,
      });
    }
    if (target.files) {
      if (target.files?.length !== 0) {
        const file = target.files[0];

        const newUrl = URL.createObjectURL(file);

        const options = {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);

          if (ocrScan) {
            setOcrFile(compressedFile);
          } else {
            setImageFile(compressedFile);
          }
          setSource(newUrl);
        } catch (error) { }
      }
    }
  };

  const validateForm = async (status) => {
    let stateValue = values;

    let newerrors = errors;

    if (status === "departure") {
      var regex = /^[a-zA-Z. ]{2,30}$/;
      if (!stateValue.name) {
        newerrors.name = "Name is Required";
      }
      if (stateValue.name && !regex.test(stateValue.name)) {
        newerrors.name = "Name Should be alphabets with minimum 2 Chars";
      }
      if (
        (!stateValue.mobile || stateValue.mobile.length < 10) &&
        stateValue.nationality == "indian"
      ) {
        newerrors.mobile = "Mobile No - Minimum 10 numbers Required";
      } else {
        newerrors.mobile = "";
      }
      if (!stateValue.address) {
        newerrors.address = "This Field is Required";
      }
      if (!stateValue.occupation) {
        newerrors.occupation = "This Field is Required";
      }
      if (!stateValue.state) {
        newerrors.state = "This Field is Required";
      }

      if (!stateValue.booking_source) {
        newerrors.booking_source = "This Field is Required";
      }

      if (stateValue.no_of_persons <= 0) {
        newerrors.no_of_persons = "This Field Should be greater than zero";
      } else if (Number(stateValue.no_of_persons) > 55) {
        newerrors.no_of_persons = "No of Persons Should be less than 55";
      }
      if (!stateValue.purpose_of_visit) {
        newerrors.purpose_of_visit = "This Field is Required";
      }
      if (!stateValue.id_proof_type) {
        newerrors.id_proof_type = "This Field is Required";
      }
      if (!stateValue.heading_to) {
        newerrors.heading_to = "Please fill 'Heading to'  field";
      }
      // if (!stateValue.receipt_request) {
      //   newerrors.receipt_request = "Please fill 'Receipt Request' field";
      // }
      let room_error_flag = false;
      let no_of_person_flag = false;
      let availableRooms = [];
      let someerr = false;

      for (var i = 0; i < stateValue.rooms_details.length; i++) {
        if (
          new Date(values.rooms_details[i].check_in_date_time) <
          new Date(values.rooms_details[i].check_out_date_time)
        ) {
          room_error_flag = true;
          someerr = true;
        }
      }

      for (var i = 0; i < stateValue.rooms_details.length; i++) {
        if (
          stateValue.rooms_details[i].type.length <= 0 ||
          !stateValue.rooms_details[i].hasOwnProperty("value") ||
          stateValue.rooms_details[i].value.length <= 0 ||
          !stateValue.rooms_details[i].check_in_date_time
        ) {
          room_error_flag = true;

          break;
        } else if (
          Number(stateValue.rooms_details[i].no_of_persons) > 10 ||
          Number(stateValue.rooms_details[i].no_of_persons) < 1
        ) {
          no_of_person_flag = true;
        } else {
          availableRooms.push(stateValue.rooms_details[i].value);
        }
      }

      if (
        room_error_flag &&
        new Set(availableRooms).size !== availableRooms.length
      ) {
        newerrors.room_details_data =
          "Fill The Details and Room Number Should be Uniq";
      } else if (room_error_flag && someerr) {
        setTstContent(
          "check-out date and time should be greater than check-in date and time"
        );
      } else if (no_of_person_flag) {
        newerrors.room_details_data =
          "No of Persons Should be in between 1-10 ";
      } else if (room_error_flag) {
        newerrors.room_details_data = "Fill The Details";
      } else if (new Set(availableRooms).size !== availableRooms.length) {
        newerrors.room_details_data = "Room Number should be uniq";
      } else {
        newerrors.room_details_data = "";
        newerrors.rooms_details = "";
      }
      if (!image || !signImage) {
        let errorTxt = "";

        if (!image) errorTxt += "Image ";

        if (
          stateValue.id_datas.some(
            (item, index) =>
              item.url == "" && stateValue.id_datas.length - 1 != index
          )
        )
          errorTxt += errorTxt != "" ? ",Id " : "Id";
        if (!signImage)
          errorTxt += errorTxt != "" ? ",Signature" : "  Signature";
        errorTxt += " is required";

        newerrors.data_upload = errorTxt;
      } else {
        newerrors.data_upload = "";
      }
      newerrors.id_datas = "";

      setErrors({ ...newerrors });

      for (let key in newerrors) {
        if (newerrors[key] != "") {
          // if (key != "heading_to") {
          //   window.scrollTo(0, 0);
          //   setTstContent(`Please fill all the mandatory fields`);
          // }

          // if (!signImage && key == "heading_to") {
          //   window.scrollTo(0, 0);
          //   setTstContent(`Please fill "Heading to" and "Signature" fields `);
          // } else if (signImage && key == "heading_to") {
          //   window.scrollTo(0, 0);
          //   setTstContent(`Please fill "Heading to"  field `);
          // } else if (key == "receipt_requested") {
          //   window.scrollTo(0, 0);
          //   setTstContent(`Please fill "Receipt Request"  field`);
          // } else if (!signImage && key != "heading_to") {
          //   window.scrollTo(0, 0);
          //   setTstContent(`Please fill "Signature"  field `);
          // }
          if (typeof newerrors[key] === "object") {
            setTstOpn(true);
          } else {
            setTstOpn(true);
            setTstContent(newerrors[key]);
          }
          window.scrollTo(0, 0);
          return;
        }
      }
    }
    let room_stats = [];

    stateValue.rooms_details.map((data) => {
      if (!data.lineEditFlag)
        room_stats.push({
          type: data.type,
          room_type_id: data.value,
          room_line_id: data.room_line_id,
          no_of_persons: data.no_of_persons,
          check_in_date_time: data.check_in_date_time,
          check_out_date_time: data.check_out_date_time,
        });
    });

    if (room_stats[0].type == "" || room_stats[0].type == null) {
      room_stats = "";
    }

    //pass customerId to check to edit the customer details in edit form otherwise put it as zero
    let stateId = { ...stateValue.state };

    var FormData = require("form-data");
    var reqData = new FormData();
    reqData.append("cust_image", imageFile);
    reqData.append("signature_image", signFile);

    let theOldIdData = [];
    for (let i = 0; i < stateValue.id_datas.length - 1; i++) {
      if (typeof stateValue.id_datas[i].file !== "string") {
        reqData.append(`id_image_${i}`, stateValue.id_datas[i].file);
      } else {
        theOldIdData.push(stateValue.id_datas[i].file);
      }
    }
    let fieldObject = {
      name: stateValue.name,
      mobile: stateValue.mobile,
      address: stateValue.address,
      customer_id: customerId,
      booking_id: bookingId,
      altranate_mobile: stateValue.altranate_mobile,
      country_id: stateValue.country.id,
      city: stateValue.city,
      occupation: stateValue.occupation,
      booking_source: stateValue.booking_source,
      check_in_date_time: stateValue.check_in_date_time,
      check_out_date_time: stateValue.check_out_date_time,
      no_of_persons: stateValue.no_of_persons,
      purpose_of_visit: stateValue.purpose_of_visit,
      id_proof_type: stateValue.id_proof_type,
      state: stateValue.state,
      room_details: room_stats,
      booking_status: status,
      cust_image: stateValue.cust_image,
      id_image: stateValue.id_image,
      signature: stateValue.signature,
      nationality: stateValue.nationality,
      heading_to: stateValue.heading_to,
      receipt_request: stateValue.receipt_request,
      purpose_of_visit_comment: stateValue.purpose_of_visit_comment,
      id_type_comment: stateValue.id_type_comment,
      id_type_passport: stateValue.id_type_passport,
      id_images: theOldIdData,
    };
    reqData.append("field", JSON.stringify(fieldObject));

    await trackPromise(
      addEditDepartureData(reqData)
        .then((res) => {
          setResDlgOpn(true);
          setTimeout(() => {
            setResDlgOpn(false);
            navigate(-1);
          }, 3000);
        })
        .catch((err) => { })
    );
  };

  const handleChangeRoomtype = (e, index) => {
    if (e.target.value) {
      let data = { ...values };
      data.rooms_details[index].type = e.target.value;

      setValues(data);

      let new_rooms = [];
      arr?.map((obj) => {
        if (obj.type === e.target.value) {
          let val = obj.values;
          val.map((data) => {
            new_rooms.push({
              value: data?.room__number,
              id: data?.room_type_id,
            });
          });
        }
      });

      data.rooms_details[index].values = new_rooms;

      setValues({ ...data });
    }
  };

  const purpose_of_visit_values = [
    { value: "Personal" },
    { value: "Official" },
    { value: "Hospital" },
    { value: "Transit" },
    { value: "Others" },
  ];

  const booking_source_values = [
    { value: "Online" },
    { value: "Offline" },
    { value: "Walk-in" },
  ];

  const receipt_request_values = [{ value: "Yes" }, { value: "No" }];

  const [shouldRemove, setShouldRemove] = useState(false);
  const [rmOpen, setRmOpen] = useState(false);
  const [index, setIndex] = useState();

  const handleSaveButtonClick = () => {
    setRmOpen(false);
    let newvalues = { ...values };

    const indexOfObject = newvalues?.rooms_details?.findIndex((object) => {
      if (
        object?.type === values?.rooms_details[index]?.type &&
        object?.values?.length == 0
      ) {
        return true;
      } else if (
        object?.type === values?.rooms_details[index]?.type &&
        object?.values[0].id === values?.rooms_details[index]?.values[0].id
      ) {
        return true;
      }
    });

    newvalues?.rooms_details.splice(index, 1);

    setValues({ ...newvalues });
    setRoomCnt(roomCnt - 1);
    if (roomCnt - 1 == 1) {
      setRemoveIcnFlg(false);
    } else {
      setRemoveIcnFlg(true);
    }
  };

  const handleConfirmRemove = (index) => {
    setIndex(index);
    setRmOpen(true);
  };

  return (
    <Box id={styles.mainbox}>
      <Appbar logo="true" />

      <Grid container spacing={3} style={{ justifyContent: "center" }}>
        <Grid item lg={5} md={5} sm={5}>
          <Box
            id={styles.firstbox}
            onClick={(item) => {
              navigate(-1);
            }}
          >
            <img
              style={{ width: "14px", height: "24px", cursor: "pointer" }}
              src="/images/Icon_left_arrow.png"
              alt="left arrow"
            />

            <CustomLabel
              style={{
                color: "white",
                margin: "8px",
                fontweight: "700",
                fontSize: "18px",
              }}
              label="Departure"
            />
          </Box>
          {searchBoxFlag && (
            <Box
              style={{
                justifyContent: "center",
              }}
            >
              <Box>
                <Box id={styles.searchbox}>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginRight: "20px",
                      "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      width: "70vw",
                    }}
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    value={rmNo}
                    onChange={(e) => {
                      let val = e.target.value;
                      val = val
                        .replace(/[^\w \t]/g, "")
                        .replace(/\t/g, "")
                        .replace(/^\s+|\s+$/gm, "");
                      val = val.toUpperCase();

                      setRmNo(val);

                      setSearchVal(val);
                    }}
                    onKeyDown={(event) => {
                      event.stopPropagation();
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    placeholder="Room No or Mobile No"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <Tooltip title="Search">
                              <SearchIcon
                                onClick={handleSearch}
                                sx={{ cursor: "pointer", color: CLR_ORANGE }}
                              />
                            </Tooltip>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  id={styles.searchbox}
                  style={{ display: "flex", justifyContent: "center" }}
                ></Box>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item lg={5} md={5} sm={5}>
          <Box></Box>
        </Grid>
      </Grid>

      {visibleFlag && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            justifyContent: "space-around",
          }}
          style={{ marinBottom: "12px", height: "50px" }}
        >
          <Box sx={{ display: "flex" }}>
            <CustomLabel label="Nationality" />
            <FormControl sx={{ color: "white" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="nationality"
                value={values?.nationality}
                defaultValue="indian"
                sx={{
                  color: disableFlg ? "#A89166" : "white",
                  marginTop: "2px",
                }}
                onChange={(e) => {
                  if (disableFlg) {
                    e.preventDefault();
                  } else {
                    handleNationalityChange(e);
                  }
                }}
              >
                <FormControlLabel
                  value="indian"
                  control={
                    <Radio sx={{ color: disableFlg ? "#A89166" : "white" }} />
                  }
                  label="Indian"
                />
                <FormControlLabel
                  value="foriegner"
                  control={
                    <Radio sx={{ color: disableFlg ? "#A89166" : "white" }} />
                  }
                  label="Foreigner"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "50px",
              justifyContent: "end",
            }}
          >
            {signImage ? (
              <Box
                sx={{
                  marginRight: "25px",
                  marginTop: "-10px",
                }}
              >
                <Box>
                  <Box
                    onClick={() => {
                      setSignDlgOpn(!signDlgOpn);
                    }}
                    id={styles.room_head}
                    sx={{
                      borderRadius: "100px",
                      cursor: "pointer",
                      marginTop: "10px",
                      maxHeight: "40px!important",
                    }}
                  >
                    <img
                      id={styles.camera}
                      style={{ background: "white" }}
                      src={signImage}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Tooltip
                title="Signature"
                sx={{ pointerEvents: disableFlg ? "none!important" : "" }}
              >
                <img
                  id={styles.camera}
                  style={{
                    marginRight: "10px",
                    marginTop: "7px",
                  }}
                  onClick={() => {
                    setSignDlgOpn(true);
                  }}
                  src="/images/icon_signature.png"
                  alt="signature icon"
                />
              </Tooltip>
            )}

            {image ? (
              <Box
                sx={{
                  marginRight: values?.id_datas?.length > 1 ? "20px" : "4px",
                }}
              >
                <Box>
                  <Box
                    onClick={() => {
                      setOpen(!open);
                      setViewFlag(false);
                      setSource(image);
                    }}
                    id={styles.room_head}
                    sx={{
                      borderRadius: "100px",
                      cursor: "pointer",
                      "&:hover": {
                        background: `${CLR_ORANGE}!important`,
                        padding: "4px",
                      },
                      pointerEvents: disableFlg ? "none!important" : "",
                      maxHeight: "40px!important",
                    }}
                  >
                    <Avatar alt="image" src={image} />
                    <Tooltip title="Delete Image ">
                      <img
                        id={styles.room_close_icon}
                        src="images/image_delete_icon.png"
                        onClick={(e) => {
                          setImage(null);
                          e.stopPropagation();
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Tooltip
                title="Camera"
                sx={{ pointerEvents: disableFlg ? "none!important" : "" }}
              >
                <label htmlFor="icon-button-file">
                  <img
                    id={styles.camera}
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setOcrScan(false);
                      setViewFlag(true);
                      setSource(null);
                      setOpen(true);
                    }}
                    src="/images/icon_camera.png"
                    alt="camera icon"
                  />
                </label>
              </Tooltip>
            )}
            {values?.id_datas?.map((item, index) => (
              <Box key={index}>
                {item?.url != "" ||
                  (item?.url == "" && values?.id_datas?.length > 1) ? (
                  <Box
                    sx={{
                      marginLeft: "-14px",
                      display:
                        values?.id_datas?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                  >
                    <Box>
                      <Box
                        onClick={(e) => {
                          setOpen(!open);
                          setViewFlag(false);
                          setSource(item.url);
                          e.stopPropagation();
                        }}
                        id={styles.room_head}
                        sx={{
                          borderRadius: "100px",
                          cursor: "pointer",
                          "&:hover": {
                            background: `${CLR_ORANGE}!important`,
                            padding: "4px",
                          },
                          pointerEvents: disableFlg ? "none!important" : "",
                          maxHeight: "40px!important",
                        }}
                      >
                        <Avatar alt="image" src={item.url} />
                        <Tooltip title="Delete Id proof ">
                          <img
                            id={styles.room_close_icon}
                            src="/images/image_delete_icon.png"
                            onClick={(e) => {
                              let newvalues = [...values.id_datas];
                              let finalData = newvalues.splice(index, 1);
                              setValues({ ...values, id_datas: newvalues });
                              e.stopPropagation();
                            }}
                          />
                        </Tooltip>
                        {index === values?.id_datas?.length - 2 &&
                          index < 4 &&
                          !disableFlg && (
                            <Tooltip title="Add ">
                              <label htmlFor="icon-button-file">
                                <AddCircleRoundedIcon
                                  id={styles.id_plus_icon}
                                  onClick={(e) => {
                                    if (idCnt < 5) {
                                      setIdCnt(idCnt + 1);
                                      setCrntIdIndex(index);

                                      setOcrScan(true);
                                      setViewFlag(true);
                                      setSource(null);
                                      setOpen(true);

                                      e.stopPropagation();
                                    }
                                  }}
                                />
                              </label>
                            </Tooltip>
                          )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Tooltip
                    title="Id Proof"
                    sx={{ pointerEvents: disableFlg ? "none!important" : "" }}
                  >
                    <label htmlFor="icon-button-file">
                      <img
                        id={styles.camera}
                        onClick={() => {
                          setOcrScan(true);
                          setViewFlag(true);
                          setSource(null);
                          setOpen(true);
                          setCrntIdIndex(index);
                        }}
                        src="/images/icon_scan.png"
                        alt="scan icon"
                      />
                    </label>
                  </Tooltip>
                )}
              </Box>
            ))}

            <Box>
              <Typography
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "white",
                  fontWeight: 700,
                  paddingLeft: "10px",
                }}
                onClick={() => {
                  setDisabeFlg(false);
                }}
              >
                Edit
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "8px" }}></Box>
          </Box>
        </Box>
      )}

      <Grid container spacing={3} style={{ justifyContent: "center" }}>
        <Grid item lg={5} md={5} sm={5}>
          {visibleFlag && (
            <Box>
              <Box>
                <CustomLabel
                  label={
                    values?.nationality != "indian" ? "Mobile" : "Mobile *"
                  }
                />
                <CustomTextField
                  placeholder="name"
                  name="mobile"
                  type="text"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  disabled={disableFlg}
                  value={values?.mobile}
                  border="1px solid #707070"
                  borderRadius="5px"
                  height="5px"
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box>
              <Box>
                <CustomLabel label="Alternate Mobile No " />
                <CustomTextField
                  placeholder="name"
                  type="text"
                  name="altranate_mobile"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  disabled={disableFlg}
                  value={values?.altranate_mobile}
                  border="1px solid #707070"
                  borderRadius="5px"
                  height="5px"
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box>
              <Box>
                <CustomLabel label="Name *" />
                <CustomTextField
                  placeholder="name"
                  name="name"
                  disabled={disableFlg}
                  value={values?.name}
                  border="1px solid #707070"
                  borderRadius="5px"
                  height="5px"
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box>

              <Box>
                <CustomLabel label="Country *" />

                <Autocomplete
                  noOptionsText={"No Options"}
                  id="combo-box-demo"
                  disableClearable
                  disabled={
                    values?.nationality == "indian" ? true : false || disableFlg
                  }
                  value={values?.country}
                  getOptionLabel={(option) =>
                    option?.label ? option?.label : ""
                  }
                  onChange={(e, newval) => {
                    setValues({
                      ...values,
                      city: null,
                      state: null,
                      country: newval,
                    });
                    getStateList(newval?.id);
                  }}
                  options={countries.filter((item) => {
                    if (
                      values?.nationality != "indian" &&
                      item?.label == "India"
                    ) {
                      return false;
                    }
                    return true;
                  })}
                  sx={{
                    background: "white!important",
                    "& .MuiInputBase-input": {
                      borderRadius: "5px",
                      position: "relative",

                      padding: "5px",
                      background: "white!important",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#E98D12!important",
                    },
                    "& .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon": {
                      color: "#E98D12!important",
                    },
                    "&.css-11qa6m6-MuiAutocomplete-root .MuiInputBase-input": {
                      border: "none",
                    },
                    "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                      padding: "0px",
                    },

                    borderRadius: "5px",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined"></TextField>
                  )}
                />
              </Box>

              <Box>
                <CustomLabel label="State *" />
                <CreatableSelect
                  isDisabled={disableFlg}
                  options={states}
                  name="state"
                  value={values.state}
                  getOptionValue={(option) => (option.id ? option.id : "")}
                  isClearable
                  onChange={(opt, meta, e) => {
                    setValues({ ...values, city: null, state: opt });
                    if (opt?.id != undefined || opt?.id != null) {
                      getCityList(opt?.id);
                    } else {
                      setCities([]);
                    }
                  }}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      marginTop: 0,
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "5px",
                      background: "white",
                      border: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      padding: "2px",
                      borderRadius: "4px",
                      position: "relative",
                      color: "black",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "#E98D12",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      color: "#E98D12",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                  formatCreateLabel={(inputValue) =>
                    `Click here to add : "${inputValue}"`
                  }
                />
              </Box>

              <Box>
                <CustomLabel label="City" />
                <CreatableSelect
                  isDisabled={disableFlg}
                  options={cities}
                  name="city"
                  value={values.city}
                  getOptionValue={(option) => (option.id ? option.id : "")}
                  isClearable
                  onChange={(opt, meta, e) => {
                    setValues({ ...values, city: opt });
                  }}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      marginTop: 0,
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "5px",
                      background: "white",
                      border: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      padding: "5px",
                      borderRadius: "5px",
                      position: "relative",
                      color: "black",
                    }),

                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "#E98D12",
                    }),
                    clearIndicator: (provided) => ({
                      ...provided,
                      color: "#E98D12",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                  formatCreateLabel={(inputValue) =>
                    `Click here to add : "${inputValue}"`
                  }
                />
              </Box>

              <Box>
                <CustomLabel label="Address *" />
                <TextareaAutosize
                  aria-label="empty textarea"
                  name="address"
                  value={values?.address}
                  onChange={(e) => {
                    if (disableFlg) {
                      e.preventDefault();
                    } else {
                      handleInputDataChange(e);
                    }
                  }}
                  style={{
                    width: "96%",
                    border: "1px solid #707070",
                    borderRadius: "5px",
                    minHeight: "32px",
                    paddingLeft: "10px",
                    fontSize: "17px",
                    fontFamily: "system-ui",
                    color: disableFlg ? "hsl(0, 0%, 50%)" : "rgba(0, 0, 0, 1)",
                  }}
                />
              </Box>

              <Box>
                <CustomLabel label="Occupation *" />
                <CustomTextField
                  placeholder="name"
                  type="text"
                  disabled={disableFlg}
                  name="occupation"
                  value={values?.occupation}
                  border="1px solid #707070"
                  borderRadius="5px"
                  height="5px"
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box>
              <Box>
                <CustomLabel label="Heading to *" />
                <CustomTextField
                  placeholder="name"
                  name="heading_to"
                  value={values?.heading_to}
                  border="1px solid #707070"
                  borderRadius="5px"
                  height="5px"
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item lg={5} md={5} sm={5} style={{ justifyContent: "center" }}>
          {visibleFlag && (
            <Box>
              <Box>
                <CustomLabel label="ID Type *" />
                <CustomSelectValue
                  disable={
                    values?.nationality != "indian" || disableFlg ? true : false
                  }
                  name="id_proof_type"
                  border="1px solid #707070"
                  borderRadius="5px"
                  padding="4px 12px 5px"
                  muiOptions={idProofTypes}
                  value={
                    values?.id_proof_type == "PassPort" ||
                      values?.id_proof_type == "Passport"
                      ? "PassPort"
                      : values?.id_proof_type
                  }
                  handleChange={(e) => {
                    handleInputDataChange(e);
                    if (e.target.value == "Others") {
                      setIdproofFlg(true);
                    } else {
                      setIdproofFlg(false);
                    }
                    if (e.target.value == "PassPort") {
                      setIdPassportFlg(true);
                    } else {
                      setIdPassportFlg(false);
                    }
                  }}
                />
              </Box>
              {idPassportFlg && (
                <Box>
                  <CustomLabel label="Passport No *" />
                  <CustomTextField
                    placeholder="Passport No"
                    name="id_type_passport"
                    disabled={disableFlg}
                    border="1px solid #707070"
                    borderRadius="5px"
                    height="0px"
                    value={values?.id_type_passport}
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
              )}

              {idproofFlg && (
                <Box>
                  <CustomLabel label="ID Type Comment *" />
                  <CustomTextField
                    placeholder="comment"
                    name="id_type_comment"
                    disabled={disableFlg}
                    border="1px solid #707070"
                    borderRadius="5px"
                    height="0px"
                    value={values?.id_type_comment}
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
              )}
              <Box>
                <CustomLabel label="Booking Source *" />
                <CustomSelectValue
                  border="1px solid #707070"
                  padding="4px 12px 5px"
                  disable={disableFlg}
                  name="booking_source"
                  value={values?.booking_source}
                  borderRadius="5px"
                  muiOptions={booking_source_values}
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box>
              <Box>
                <CustomLabel label="Purpose of Visit *" />
                <CustomSelectValue
                  border="1px solid #707070"
                  disable={disableFlg}
                  padding="4px 12px 5px"
                  name="purpose_of_visit"
                  value={values?.purpose_of_visit}
                  borderRadius="5px"
                  muiOptions={purpose_of_visit_values}
                  handleChange={(e) => {
                    handleInputDataChange(e);
                    if (e.target.value == "Others") {
                      setPurposeCmntFlg(true);
                    } else {
                      setPurposeCmntFlg(false);
                    }
                  }}
                />
              </Box>
              {purposeCmntFlg && (
                <Box>
                  <CustomLabel label="Purpose of visit Comment *" />

                  <CustomTextField
                    placeholder="comment"
                    name="purpose_of_visit_comment"
                    disabled={disableFlg}
                    border="1px solid #707070"
                    value={values?.purpose_of_visit_comment}
                    borderRadius="5px"
                    height="0px"
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
              )}

              <Box>
                {[...Array(roomCnt)].map((elementInArray, index) => (
                  <Box
                    sx={{
                      color: "white",
                      marginBottom: "12px",
                    }}
                    key={index}
                  >
                    <CustomLabel label={`Room Details ${index + 1}`} />
                    <Box
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <CustomSelectValue
                        placeholder="select value"
                        border="1px solid #707070"
                        disable={true}
                        borderRadius="5px"
                        padding="4px 12px 5px"
                        marginRight="5px"
                        value={
                          values?.rooms_details?.length > 0
                            ? values?.rooms_details[index].type
                            : ""
                        }
                        name="name"
                        muiOptions={roomMuiOpt}
                        handleChange={(e) => {
                          handleChangeRoomtype(e, index);
                        }}
                      />

                      <CustomSelect
                        placeholder="Room Number"
                        border="1px solid #707070"
                        disable={true}
                        borderRadius="5px"
                        value={values?.rooms_details[index].value}
                        name="room_number"
                        marginRight="5px"
                        muiOptions={values?.rooms_details[index].values}
                        handleChange={(e) => {
                          handleChangeRoomtypeValue(e, index);
                        }}
                      />
                      {removeIcnFlg && (
                        <RemoveCircleRoundedIcon
                          style={{
                            color: "#E98D12",
                            marginTop: "6px",
                            position: "absolute",
                            right: "8%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (roomCnt > 1) {
                              handleConfirmRemove(index);
                            } else {
                              setRemoveIcnFlg(false);
                            }
                          }}
                        />
                      )}

                      <Box>
                        <Box id={styles.searchbox}>
                          <TextField
                            fullWidth
                            name="no_of_persons"
                            type="number"
                            id="standard-disabled"
                            disabled={disableFlg}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "5px",
                                height: "38px",
                              },
                              background: "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            onChange={(e) => {
                              let data = { ...values };
                              data.rooms_details[index].no_of_persons = Number(
                                e.target.value
                              );

                              setValues({ ...data });
                            }}
                            placeholder="No Of Persons"
                            variant="outlined"
                            value={values?.rooms_details[index].no_of_persons}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Box>
                        <CustomLabel label="Check-in date & time*" />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDateTimePicker
                            disabled={Number(USER_DATA?.role) == 1 ? values?.rooms_details[index].lineEditFlag || disableFlg : true}
                            disablePast={true}
                            value={
                              values?.rooms_details[index].check_in_date_time
                            }
                            onChange={(newValue) => {
                              let data = { ...values };
                              let room_stats = data?.rooms_details;

                              room_stats[index].check_in_date_time = newValue;

                              setValues({
                                ...data,
                                room_details: room_stats,
                              });
                            }}
                            name="check_in_date_time"
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    height: "5px",
                                  },

                                  "& .MuiInputBase-input": {},
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #707070",
                                    borderRadius: "5px",
                                    borderColor: "black!important",
                                  },
                                }}
                                style={{
                                  background: "#FFFFFF",
                                  borderRadius: "5px",
                                  border: "none",
                                  borderColor: "black",
                                }}
                                fullWidth
                                {...params}
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>

                      <Box>
                        <CustomLabel label="Check-out date & time*" />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDateTimePicker
                            disabled={Number(USER_DATA?.role) == 1 ? values?.rooms_details[index].lineEditFlag : true}
                            value={
                              values?.rooms_details[index].check_out_date_time
                            }
                            minDateTime={
                              !editFlag &&
                              new Date(
                                values?.rooms_details[index].check_in_date_time
                              )
                            }
                            ampm={true}
                            disableFuture={true}
                            disablePast={true}
                            maxTime={new Date()}
                            onChange={(newValue) => {
                              let data = { ...values };
                              let room_stats = data?.rooms_details;

                              room_stats[index].check_out_date_time = newValue;

                              setValues({
                                ...data,
                                room_details: room_stats,
                              });
                            }}
                            name="check_out_date_time"
                            style={{
                              "&.MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root":
                              {
                                color: CLR_ORANGE,
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    height: "5px",
                                  },
                                  "& .MuiInputBase-input": {},
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #707070",
                                    borderRadius: "5px",
                                    borderColor: "black!important",
                                  },
                                }}
                                style={{
                                  background: "#FFFFFF",
                                  borderRadius: "5px",
                                  border: "none",
                                  borderColor: "black",
                                }}
                                fullWidth
                                {...params}
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              {/* <Box>
                <CustomLabel label="Is Receipt Requested *" />
                <CustomSelectValue
                  border="1px solid #707070"
                  padding="4px 12px 5px"
                  // disable={disableFlg}
                  name="receipt_request"
                  value={values?.receipt_request}
                  borderRadius="5px"
                  muiOptions={receipt_request_values}
                  handleChange={(e) => {
                    handleInputDataChange(e);
                  }}
                />
              </Box> */}
            </Box>
          )}
        </Grid>
      </Grid>
      {visibleFlag && (
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          paddingTop={8}
        >
          {room_no && (
            <Button
              variant="contained"
              onClick={() => {
                validateForm("departure_draft");
                setbBookingStatus("departure_draft");
                setResultLabel("Saved as draft successfull !");
              }}
              id={styles.save_draft_button}
            >
              Save as Draft
            </Button>
          )}

          <Button
            variant="contained"
            disabled={editFlag}
            onClick={() => {
              setClrDlgOpn(true);
              setResultLabel("Are you sure to clear all the info?");
            }}
            id={styles.clear_button}
            sx={{ display: "none" }}
          >
            Clear
          </Button>

          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              setbBookingStatus("departure");
              setResultLabel("Departure successfull !!!");
              validateForm("departure");
            }}
            id={styles.save_button}
          >
            Save
          </Button>
        </Stack>
      )}

      {open && (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{
            "& .MuiPaper-root": {
              background: CLR_DLG_WHITE,
              borderRadius: "20px",
            },
            "& .MuiDialogContent-root": {
              border: "none",
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {viewFlag && (
              <Typography sx={{ color: CLR_ORANGE }}>Take Photo</Typography>
            )}
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <Tooltip title="Close">
                <img
                  style={{ width: "20px", height: "24px", cursor: "pointer" }}
                  src="/images/icon_close_circle_orange.png"
                  onClick={(item) => {
                    setSource(null);
                    setOpen(false);
                  }}
                />
              </Tooltip>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Box
              style={{
                justifyContent: "center",
                marginLeft: "20px",
                marginRight: "20px",
                position: "relative",
                display: "flex",
                height: "360px",
                width: "40vw",
              }}
            >
              {source ? (
                <Box
                  justifyContent="center"
                  style={{
                    width: "35vw",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={source}
                    style={{ width: "30vw", height: "300px" }}
                    alt={"snap"}
                  ></img>
                </Box>
              ) : (
                <Box
                  justifyContent="center"
                  style={{
                    width: "35vw",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="images/img_enlarged_logo.png"
                    style={{
                      height: "300px",
                      width: "30vw",
                      background: CLR_LIGHT_GREY,
                    }}
                    alt={"snap"}
                  ></img>
                </Box>
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",

                  top: "255px",
                  position: "absolute",
                }}
              >
                <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  ref={fileUpload}
                  onChange={(e) => handleCapture(e.target)}
                  style={{ display: "none" }}
                />
              </Box>
            </Box>
          </DialogContent>
          {viewFlag && (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
              sx={{
                paddingBottom: "25px",
                marginTop: "10px",
              }}
            >
              <Button
                disabled={!source}
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  background: `${CLR_LIGHT_WHITE}`,
                  borderRadius: "10px",
                  shadowColor: "1px 1px 6px #E98D12",
                  color: `${CLR_ORANGE}`,
                  "&:hover": {
                    color: `${CLR_ORANGE}`,
                    background: `${CLR_LIGHT_WHITE}`,
                  },
                }}
                variant="contained"
                onClick={() => {
                  ocrScan ? setIdImage(source) : setImage(source);

                  if (ocrScan) {
                    let data = [...values.id_datas];
                    let len = values?.id_datas?.length;

                    data[len - 2].file = ocrFile;
                    data[len - 2].url = source;

                    setValues({ ...values, id_datas: data });
                  }

                  setOpen(false);
                }}
              >
                {ocrScan ? "Save as ID" : "Save as Image"}
              </Button>
              <Button
                variant="contained"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  background: `${CLR_ORANGE}`,
                  borderRadius: "10px",
                  shadowColor: "1px 1px 6px #E98D12",
                  "&:hover": {
                    color: `${CLR_ORANGE}`,
                    background: `${CLR_LIGHT_WHITE}`,
                  },
                }}
                onClick={() => {
                  if (ocrScan && source) {
                    let newvalues = [...values.id_datas];
                    let finalData = newvalues.splice(
                      values?.id_datas?.length - 2,
                      1
                    );
                    setValues({ ...values, id_datas: newvalues });
                  }
                  setIdCnt(idCnt - 1);
                  setSource(null);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Dialog>
      )}

      {rmOpen && (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={rmOpen}
          sx={{
            "& .MuiPaper-root": {
              background: CLR_LIGHT_WHITE,
              borderRadius: "20px",
              width: "600px",
              height: "200px",
            },
            "& .MuiDialogContent-root": {
              border: "none",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              overflow: "hidden",
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Tooltip title="Close">
              <img
                style={{
                  width: "20px",
                  height: "24px",
                  cursor: "pointer",
                  float: "right",
                }}
                src="/images/icon_close.png"
                onClick={(item) => {
                  setRmOpen(false);
                }}
              />
            </Tooltip>
          </DialogTitle>
          <DialogContent dividers>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "black",
                  justifyContent: "center",
                  fontSize: "18px",
                }}
              >
                {"Are you sure you want to remove this room?"}
              </Typography>
            </Box>
          </DialogContent>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={4}
            style={{ paddingTop: "20px", paddingBottom: "30px" }}
          >
            <CustomCancelButton
              label="CANCEL"
              width="160px"
              handleChange={() => {
                setRmOpen(false);
              }}
            />

            <CustomSaveButton
              width="160px"
              label="CONFIRM"
              handleChange={handleSaveButtonClick}
            />
          </Stack>
        </Dialog>
      )}

      {signDlgOpn && (
        <SignatureDiologue
          open={signDlgOpn}
          setSignFile={setSignFile}
          setSignImage={setSignImage}
          setOpen={setSignDlgOpn}
          signImage={signImage}
          label={resultLabel}
        />
      )}

      {resDlgOpn && (
        <ResultDiologueBox
          open={resDlgOpn}
          setOpen={setResDlgOpn}
          label={resultLabel}
          width="400px"
          height="150px"
        />
      )}

      {clrDlgOpn && (
        <ClearRoomDiologueBox
          open={clrDlgOpn}
          clearForm={clearForm}
          setOpen={setClrDlgOpn}
          label={resultLabel}
        />
      )}
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={tstContent}
      />
    </Box>
  );
}

export default DepartureForm;
